import React, { Suspense, useEffect, useMemo } from 'react'
import store from './redux/store'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { CONSTANTS } from './constants'
import { CookiesProvider } from 'react-cookie'
import { AuthProvider, useAuth } from './contexts/Auth'
import PrivateRoute from './Pages/PrivateRoute'
import Create from './Pages/Create/Create'
import Gallery from './Pages/Choose'
import Unknown from './Pages/404'
import New from './Pages/New'
import { HelmetProvider } from 'react-helmet-async'
import { PostHogProvider, useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'
import { selectUser, setPaddle } from './redux/systemSlice'
import { useCookieUser } from './hooks/cookieUser'
import Thanks from './Components/Modals/Thanks'
import useUniqueId from './hooks/uniqueId'
import HumanizerPage from './Pages/Humanizer'
import DetectorPage from './Pages/Detector'
import Home from './Pages/Home'
import Pricing from './Pages/Pricing'
import WriterPage from './Pages/Writer'
import PrivacyPolicy from './Pages/Privacy'
import TermsOfService from './Pages/Terms'
import DetectorLandingPage from './Pages/DetectorLanding'
import HumanizerLanding from './Pages/HumanizerLanding'
import { Crisp } from 'crisp-sdk-web'
import { FEATURE_FLAGS } from './hooks/featureFlags'
import useIsPremium from './hooks/useIsPremium'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { useLocalStorage } from './helpers/utility'
import * as Sentry from '@sentry/react'
import SidePanelLayout from './Components/SidePanel'
import Navbar from './Components/Navbar/Navbar'
import Post from './Pages/Post'
import { initializePaddle, Paddle, Environments } from '@paddle/paddle-js'
// import Post from './Pages/Post'

export function getCookie(cname: string) {
	let name = cname + '='
	let decodedCookie = decodeURIComponent(document.cookie)
	let ca = decodedCookie.split(';')
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i]
		while (c.charAt(0) === ' ') {
			c = c.substring(1)
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length)
		}
	}
	return ''
}

export const contactFormLink = 'https://the-good-ai.helpscoutdocs.com'
const options = {
	api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
	autocapture: false,
}

function App() {
	return (
		<React.StrictMode>
			<PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY as string} options={options}>
				<Provider store={store}>
					<AuthProvider>
						<CookiesProvider>
							<HelmetProvider>
								<ToastContainer />
								<Routes />
							</HelmetProvider>
						</CookiesProvider>
					</AuthProvider>
				</Provider>
			</PostHogProvider>
		</React.StrictMode>
	)
}

const Routes = () => {
	const [gclid, setGClid] = useLocalStorage<string | undefined>('gclid', undefined)
	const [fBClid, setFBClid] = useLocalStorage<string | undefined>('fbclid', undefined)
	const [scClid, setSCClid] = useLocalStorage<string | undefined>('scClid', undefined)
	const crispChatFF = useFeatureFlagVariantKey(FEATURE_FLAGS.CRISP_CHAT)
	const sidePanelFF = useFeatureFlagVariantKey(FEATURE_FLAGS.SIDE_PANEL)
	const user = useSelector(selectUser)
	const { userIDCookie } = useCookieUser()
	const posthog = usePostHog()
	const uniqueId = useUniqueId()
	const isPremium = useIsPremium()
	const { currentUser } = useAuth() as any
	const dispatch = useDispatch()

	const searchParamsString = document.location.search

	const searchParams = useMemo(() => {
		let params = new URLSearchParams(searchParamsString)

		return {
			gclid: params.getAll('gclid')[0],
			fbclid: params.getAll('fbclid')[0],
			ScCid: params.getAll('ScCid')[0],
		}
	}, [searchParamsString])

	useEffect(() => {
		if (searchParams.gclid) {
			setGClid(searchParams.gclid)
		}
	}, [searchParams.gclid, setGClid])

	useEffect(() => {
		if (searchParams.fbclid) {
			setFBClid(searchParams.fbclid)
		}
	}, [searchParams.fbclid, setFBClid])

	useEffect(() => {
		if (searchParams.ScCid) {
			setSCClid(searchParams.ScCid)
		}
	}, [searchParams.ScCid, setSCClid])

	useEffect(() => {
		if (!uniqueId) return
		posthog?.identify(uniqueId, {
			email: currentUser ? currentUser.email : '',
			id: user?.id,
			premium: user?.planId ? 'true' : 'false',
			stripe_id: user?.stripeId,
			cookie_id: userIDCookie,
			created_at: user?.joined,
		})
	}, [posthog, user.id, user.email, userIDCookie, uniqueId, user.planId, user.stripeId, user.joined, currentUser])

	useEffect(() => {
		Crisp.configure('49858935-7976-4728-8db9-e273d870bc17', {
			autoload: false,
		})
	}, [])

	useEffect(() => {
		if (crispChatFF) {
			Crisp.load()
			// @ts-ignore
			Crisp.setColorTheme('black')
		}
	}, [crispChatFF])

	useEffect(() => {
		if (!crispChatFF) {
			return
		}
		Crisp.session.setData({
			user_id: user?.id,
			email: user?.email,
			plan: isPremium ? 'premium' : 'free',
			stripe_id: user?.stripeId,
		})
	}, [user.id, user.stripeId, isPremium, crispChatFF, user.email])

	useEffect(() => {
		const updateUserGclid = async () => {
			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
				},
				body: JSON.stringify({ user_id: user.id, gclid: gclid }),
			}
			fetch(process['env']['REACT_APP_API_ROOT'] + '/user/update/', requestOptions).catch((err) =>
				Sentry.captureException(err)
			)
		}
		if (gclid && currentUser && user.id && !user.gclid) {
			updateUserGclid()
		}
	}, [user.id, gclid])

	useEffect(() => {
		const updateUserFbclid = async () => {
			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
				},
				body: JSON.stringify({ user_id: user.id, fbclid: fBClid }),
			}
			fetch(process['env']['REACT_APP_API_ROOT'] + '/user/update/', requestOptions).catch((err) =>
				Sentry.captureException(err)
			)
		}
		if (fBClid && currentUser && user.id) {
			updateUserFbclid()
		}
	}, [user, fBClid])

	useEffect(() => {
		const updateUserScCid = async () => {
			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
				},
				body: JSON.stringify({ user_id: user.id, scclid: scClid }),
			}
			fetch(process['env']['REACT_APP_API_ROOT'] + '/user/update/', requestOptions).catch((err) =>
				Sentry.captureException(err)
			)
		}
		if (scClid && currentUser && user.id) {
			updateUserScCid()
		}
	}, [user, scClid])

	useEffect(() => {
		const updateUserPhid = async () => {
			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
				},
				body: JSON.stringify({ user_id: user.id, phid: posthog.get_distinct_id() }),
			}
			fetch(process['env']['REACT_APP_API_ROOT'] + '/user/update/', requestOptions).catch((err) =>
				Sentry.captureException(err)
			)
		}

		if (posthog.get_distinct_id() && currentUser && user.id && !user.phid) {
			updateUserPhid()
		}
	}, [user.id, posthog, currentUser])

	useEffect(() => {
		initializePaddle({
			environment: 'production',
			token: 'live_280e85651c16d0cf90ba95c49a0',
			// token: 'test_7070b0c72038eacbf5722a4549d', # sandbox
			pwCustomer: user?.paddleId,
		}).then((paddleInstance: Paddle | undefined) => {
			if (paddleInstance) {
				dispatch(setPaddle(paddleInstance))
			}
		})
	}, [])

	return (
		<BrowserRouter>
			<Suspense fallback={<div></div>}>
				<Thanks />
				{sidePanelFF !== 'test' && <Navbar />}
				<Switch>
					<PrivateRoute
						loginRequired={false}
						component={(props: any) => (
							<SidePanelLayout>
								<Create
									type={props.match.params.type}
									docID={props.match.params.id ? atob(props.match.params.id) : ''}
								/>
							</SidePanelLayout>
						)}
						path={CONSTANTS.CREATE_PATH + '/:type/:id'}
					/>
					<PrivateRoute
						loginRequired={false}
						path={CONSTANTS.DOC_PATH}
						component={() => (
							<SidePanelLayout>
								<Gallery />
							</SidePanelLayout>
						)}
					/>
					<Route
						path="/new/:template"
						render={(props) => (
							<SidePanelLayout>
								<New template={props.match.params.template} />
							</SidePanelLayout>
						)}
					/>
					<Route
						path="/humanizer"
						render={() => (
							<SidePanelLayout>
								<HumanizerPage />
							</SidePanelLayout>
						)}
					/>
					<Route path="/ai-humanizer" render={() => <HumanizerLanding />} />
					<Route
						path="/detector"
						render={() => (
							<SidePanelLayout>
								<DetectorPage />
							</SidePanelLayout>
						)}
					/>
					<Route path="/ai-detector" render={() => <DetectorLandingPage />} />
					<Route path="/writer" render={() => <WriterPage />} />
					<Route exact path="/pricing" render={() => <Pricing />} />
					<Route exact path="/privacy" render={() => <PrivacyPolicy />} />
					<Route exact path="/terms" render={() => <TermsOfService />} />
					<Route path="/post/:title" render={(props) => <Post path={props.match.params.title} />} />
					<Route exact path="/" render={() => <Home />} />
					<Route path="/404" component={Unknown} />
					<Redirect to="/404" />
				</Switch>
			</Suspense>
		</BrowserRouter>
	)
}

export default App
