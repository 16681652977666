import './_Thanks.scss'
import Modal from './Modal'
import { FaClock } from 'react-icons/fa6'
import { Button } from '../../components-landing/ui/button'
import { useIsOnFreeTrial } from '../../hooks/useIsPremium'
import { useSelector } from 'react-redux'
import { selectUserLoading } from '../../redux/systemSlice'
import Loader from '../Loader'

const ThanksModal = ({ open, closeModal }: { open: boolean; closeModal: () => void }) => {
	const isOnFreeTrial = useIsOnFreeTrial()
	const userLoading = useSelector(selectUserLoading)

	const modalContent = isOnFreeTrial ? (
		<div className="flex flex-col gap-4 items-center">
			<FaClock className="p-2 bg-primary-100 rounded-full w-10 h-10 text-primary-700" />
			<div className="text-2xl text-center font-semibold">🎉 1-Day Trial Activated!</div>
			<div className="text-gray-700 text-center">
				Welcome to Clarify! You've activated a one-day trial of our <b className="text-black">Premium</b> plan, giving
				you access to <b className="text-black">600 humanization credits</b>. Should you exhaust your credits, you can
				easily upgrade to a full Premium subscription to enjoy unlimited access.
			</div>
			<div className="bg-primary-50 p-4 rounded-xl flex flex-col gap-4 w-full">
				<div className="uppercase font-semibold text-sm">Access to all of our features</div>
				<div className="text-gray-800 text-sm">
					With your free trial, enjoy access to all features available to our Premium subscribers. Please reach out to
					support@clarify.ai if you have any questions!
				</div>
				<div className="uppercase font-semibold text-sm">AI Detection Bypass and Humanizer</div>
				<div className="text-gray-800 text-sm">
					Turn AI-written text into natural, human-like content with our AI Humanizer. Clarify AI helps you easily
					bypass AI detectors like Turnitin, Originality, and GPTZero, all while enhancing tone and flow.
				</div>
				<div className="uppercase font-semibold text-sm">AI Detector</div>
				<div className="text-gray-800 text-sm">
					Verify that your content is indistinguishable from human writing by testing it against the leading AI
					detectors.
				</div>
				<div className="font-semibold text-sm mt-2">
					👇 Unlock the full potential of humanized content with Clarify AI!
				</div>
				<Button onClick={closeModal} className="w-full mt-4">
					Get Started
				</Button>
			</div>
		</div>
	) : (
		<div className="flex flex-col gap-4 items-center">
			<FaClock className="p-2 bg-green-100 rounded-full w-10 h-10 text-green-600" />
			<div className="text-2xl text-center font-semibold">🎉 Checkout Successful!</div>
			<div className="text-gray-700 text-center">
				Thanks for choosing Clarify! You've successfully upgraded to our <b className="text-black">Unlimited</b> plan.
			</div>
			<div className="bg-primary-50 p-4 rounded-xl flex flex-col gap-4 w-full">
				<div className="uppercase font-semibold text-sm">Access to all of our features</div>
				<div className="text-gray-800 text-sm">
					As an Unlimited user, enjoy unlimited access to all our powerful features. Please reach out to
					support@clarify.ai if you have any questions!
				</div>
				<div className="uppercase font-semibold text-sm">AI Detection Bypass and Humanizer</div>
				<div className="text-gray-800 text-sm">
					Turn AI-written text into natural, human-like content with our AI Humanizer. Clarify AI helps you easily
					bypass AI detectors like Turnitin, Originality, and GPTZero, all while enhancing tone and flow.
				</div>
				<div className="uppercase font-semibold text-sm">AI Detector</div>
				<div className="text-gray-800 text-sm">
					Verify that your content is indistinguishable from human writing by testing it against the leading AI
					detectors.
				</div>
				<div className="font-semibold text-sm mt-2">
					👇 Unlock the full potential of humanized content with Clarify AI!
				</div>
				<Button onClick={closeModal} className="w-full mt-4">
					Get Started
				</Button>
			</div>
		</div>
	)

	return (
		<Modal open={open} closeModal={closeModal} portalClassName="thanks">
			{userLoading ? (
				<div className="py-12">
					<Loader />
				</div>
			) : (
				modalContent
			)}
		</Modal>
	)
}

export default ThanksModal
