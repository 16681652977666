import { ArrowLeftRight, BookOpenText, Lock, QuoteIcon } from 'lucide-react'
import FAQ from '../components-landing/faq'
import TryFree from '../components-landing/commons/try-free'
import { useEffect, useState } from 'react'
import Footer from '../Components/Footer'
import GetStartedButton from '../components-landing/get-started'
import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'
import Navbar from '../Components/Navbar/Navbar'
import { FEATURE_FLAGS } from '../hooks/featureFlags'
import WordCount from '../assets/images/wordcount.png'
import EssayType from '../assets/images/essaytype.png'
import WritingStyle from '../assets/images/writingstyle.png'
import WriterHumanization from '../assets/images/writer-humanization.png'
import Subtopics from '../assets/images/subtopics.png'

const WriterPage = () => {
	const [step, setStep] = useState(0)
	const posthog = usePostHog()
	const sidePanelFF = useFeatureFlagVariantKey(FEATURE_FLAGS.SIDE_PANEL)

	useEffect(() => {
		// increment the step every 5 seconds, and reset it after it reaches 4
		const interval = setInterval(() => {
			setStep((prev) => (prev + 1) % 5)
		}, 3000)
		return () => clearInterval(interval)
	}, [step])

	useEffect(() => {
		posthog.capture('page-viewed', { page: 'writer' })
		window.scrollTo(0, 0)
	}, [])

	return (
		<>
			{sidePanelFF === 'test' && <Navbar />}
			<div className="relative">
				<div className="w-full h-full bg-gradient-to-b from-white to-[#346eed]  absolute bottom-0 opacity-20 z-[-10] md:block hidden" />
				<div className="mt-36 mx-auto flex flex-col gap-10 px-2 md:px-0 pb-12">
					<div className="md:max-w-3xl flex flex-col items-center mx-auto gap-2">
						<div className="flex gap-2 items-center text-success-default bg-opacity-20 py-2 px-4 rounded-2xl shadow-[0_0_50px_20px_rgba(76,175,80,0.3)]">
							<h1 className="md:text-6xl text-4xl font-bold text-center md:text-left">
								<Lock className="inline-block mb-2" size={48} /> Undetectable Content
							</h1>
						</div>
						<h1 className="md:text-6xl text-4xl font-bold mt-3 text-center">written in seconds</h1>
						<div className="text-gray-700 text-center my-4 text-xl">
							<span className="font-semibold text-black">Join 4M+ writers.</span> Generate an essay, research paper,
							article, or any other type of content within seconds, with in-text citations from real articles, journals,
							and websites.
						</div>
						<GetStartedButton redirectUrl="/choose" />
					</div>
					<div className="max-w-6xl w-full mx-auto">
						<video src="/writer.mp4" className="rounded-xl shadow" autoPlay loop muted playsInline controls={false} />
					</div>
				</div>
			</div>
			<div>
				<div className="grid grid-cols-1 md:grid-cols-[1fr,2fr] gap-16 md:pb-24 pb-16 md:pt-32 pt-24 max-w-7xl mx-auto px-2 md:px-0">
					<div className="flex flex-col gap-3 justify-center">
						<h2 className=" md:text-3xl text-xl">Why Choose Clarify?</h2>
						<div className="text-gray-700 text-lg">
							Clarify is the only AI writer that offers real citations, an AI humanizer, and document export—all in one
							place.
						</div>
						<div className="flex gap-4 mt-4">
							<div className="h-full w-1 bg-gray-200">
								<div
									className={`w-1 bg-black transition-all z-10`}
									style={{
										height: step === 4 ? '100%' : `calc(${(step + 1) * 20}% - 17px)`,
									}}
								/>
							</div>
							<div className="flex flex-col gap-3">
								{[
									'Enter word count',
									'Select essay type',
									'Generate writing style',
									'Select humanization level',
									'Choose subtopics',
								].map((text, index) => (
									<div
										key={index}
										className={`text-lg cursor-pointer ${index === step ? 'text-black font-medium' : 'text-gray-600'}`}
										onClick={() => setStep(index)}
									>
										{text}
									</div>
								))}
							</div>
						</div>
					</div>
					<img
						src={[WordCount, EssayType, WritingStyle, WriterHumanization, Subtopics][step]}
						className="rounded-xl overflow-hidden object-cover object-top w-full border border-gray-300 shadow-lg"
					/>
				</div>
			</div>
			<section className="py-16 relative">
				<div className="w-full h-full bg-gradient-to-b from-white to-[#346eed]  absolute bottom-0 opacity-20 z-[-10]" />

				<div className="text-center max-w-4xl mx-auto px-2 md:px-0">
					<h1 className="text-3xl sm:text-4xl font-semibold text-blue-600 mb-4">Empower your writing with AI</h1>
					<p className="text-lg sm:text-xl text-gray-700 font-medium mb-8">
						Create an essay, research paper, article, or any other type of content within seconds, with in-text
						citations from real articles, journals, and websites.
					</p>
				</div>

				<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto px-2 md:px-0">
					<div className="bg-white p-6 rounded shadow-md border border-gray-300">
						<QuoteIcon className="mb-4" />
						<h3 className="text-lg font-medium mb-2">Real Citations </h3>
						<div className="text-gray-900">
							Get real, live citations pulled from internet sources to use in your content.
						</div>
					</div>
					<div className="bg-white p-6 rounded shadow-md border border-gray-300">
						<ArrowLeftRight className="mb-4" />
						<h3 className="text-lg font-medium mb-2">AI Humanizer & Detector</h3>
						<div className="text-gray-900">
							Bypass AI detectors from leading tools like TurnItIn with our AI humanizer.
						</div>
					</div>
					<div className="bg-white p-6 rounded shadow-md border border-gray-300">
						<BookOpenText className="mb-4" />
						<h3 className="text-lg font-medium mb-2">Custom Writing Style</h3>
						<div className="text-gray-900">
							Turn our AI into your personal ghostwriter by uploading a writing sample.
						</div>
					</div>
				</div>

				<div className="text-center mt-10">
					<GetStartedButton redirectUrl="/choose" />
				</div>
			</section>
			{/* <section className="bg-white md:pt-24 pt-16 md:pb-24 pb-36 px-4 sm:px-8 lg:px-16 flex flex-col md:gap-5 gap-1 max-w-7xl mx-auto">
				<h2 className="text-center md:text-4xl text-3xl font-semibold mb-4">
					Everything you need <span className="text-secondary">in one tool</span>
				</h2>
				<div className="grid md:grid-cols-2 md:gap-5 gap-1">
					<div className="bg-gray-100 p-7 rounded-xl md:h-[550px] hover:shadow-md transition-all">
						<div className="flex flex-col gap-5">
							<FileDown className="self-start" />
							<div className="flex flex-col gap-1">
								<div className="font-semibold">Document Export</div>
								<div className="text-gray-800 text-sm">
									Your content comes pre-formatted in the editor—just export and submit!
								</div>
							</div>
						</div>
					</div>
					<div className="grid grid-cols-2 md:gap-5 gap-1">
						{[
							{
								icon: FileDown,
								title: 'Document Export',
								description: 'Your content comes pre-formatted in the editor—just export and submit!',
							},
							{
								icon: FileCheck2,
								title: 'Accurate Detection',
								description:
									'Detect AI-authored text instantly with our free tool and make changes to ensure your content passes AI detectors.',
							},
							{
								icon: BadgeDollarSign,
								title: 'Completely Free',
								description: 'Enjoy unrestricted access to our AI detector at no charge, with no subscription.',
							},
							{
								icon: UserRoundPen,
								title: 'Humanization',
								description:
									'Is your content being flagged as AI-written? Use our humanizer to ensure it bypasses all detection tools.',
							},
						].map((feature, index) => (
							<div key={index} className="bg-gray-100 p-7 rounded-xl hover:shadow-md transition-all">
								<div className="flex flex-col justify-between h-full">
									<feature.icon size={30} className="self-start" />
									<div className="flex flex-col gap-1">
										<div className="font-semibold">{feature.title}</div>
										<div className="text-gray-800 text-sm">{feature.description}</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
				<div className="grid md:grid-cols-4 h-72 md:gap-5 gap-1">
					{[
						{
							icon: FileDown,
							title: 'Document Export',
							description: 'Your content comes pre-formatted in the editor—just export and submit!',
						},
						{
							icon: FileCheck2,
							title: 'Accurate Detection',
							description:
								'Detect AI-authored text instantly with our free tool and make changes to ensure your content passes AI detectors.',
						},
					].map((feature, index) => (
						<div key={index} className="bg-gray-100 p-7 rounded-xl hover:shadow-md transition-all">
							<div className="flex flex-col justify-between h-full">
								<feature.icon size={30} className="self-start" />
								<div className="flex flex-col gap-1">
									<div className="font-semibold">{feature.title}</div>
									<div className="text-gray-800 text-sm">{feature.description}</div>
								</div>
							</div>
						</div>
					))}
					<div className="bg-gray-100 p-7 rounded-xl col-span-2 hover:shadow-md transition-all">
						<div className="flex flex-col md:gap-5 gap-1 justify-between h-full">
							<div />
							<div className="flex flex-col gap-1">
								<div className="font-semibold">Document Export</div>
								<div className="text-gray-800 text-sm">
									Your content comes pre-formatted in the editor—just export and submit!
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div className="px-2 md:px-0">
				<LookingToBypass />
			</div> */}
			{/* 
			<div className="bg-gray-100 w-full py-24">
				<div className="max-w-6xl w-full mx-auto rounded-lg">
					<div className="flex flex-col items-center gap-5">
						<Badge size="lg" className="gap-2 bg-secondary bg-opacity-20 font-bold uppercase text-secondary">
							<Sparkle /> TOOLS
						</Badge>
						<div className="flex flex-col gap-6 max-w-4xl mx-auto">
							<h2 className="text-center md:text-6xl text-5xl">Empower your writing with AI</h2>
							<div className="text-gray-700 text-center text-lg">
								Create an essay, research paper, article, or any other type of content within seconds, with in-text
								citations from real articles, journals, and websites.
							</div>
						</div>
						<div className="grid grid-cols-1 md:grid-cols-3 gap-10 mt-10 mb-4">
							<div className="flex flex-col gap-4 items-center shadow-lg bg-white border px-3 py-6 rounded-xl border-gray-300">
								<div className="h-14 w-14 bg-gray-300 rounded-full flex items-center justify-center text-secondary">
									<Quote size={24} />
								</div>
								<h3 className="text-xl text-center">Real Citations</h3>
								<p className="text-lg text-gray-700 text-center">
									Get real, live citations pulled from internet sources to use in your content.
								</p>
							</div>
							<div className="flex flex-col gap-4 items-center shadow-lg bg-white border px-3 py-6 rounded-xl border-gray-300">
								<div className="flex justify-center gap-2">
									<div className="h-14 w-14 bg-gray-300 rounded-full flex items-center justify-center text-secondary">
										<FileSearch size={24} />
									</div>
									<div className="h-14 w-14 bg-gray-300 rounded-full flex items-center justify-center text-secondary">
										<PersonStanding size={24} />
									</div>
								</div>
								<h3 className="text-xl text-center">AI Humanizer & Detector</h3>
								<p className="text-lg text-gray-700 text-center">
									Bypass AI detectors from leading tools like TurnItIn with our AI humanizer.
								</p>
							</div>
							<div className="flex flex-col gap-4 items-center shadow-lg bg-white border px-3 py-6 rounded-xl border-gray-300">
								<div className="flex justify-center gap-2">
									<div className="h-14 w-14 bg-gray-300 rounded-full flex items-center justify-center text-secondary">
										<FileDown size={24} />
									</div>
								</div>
								<h3 className="text-xl text-center">Document Export</h3>
								<p className="text-lg text-gray-700 text-center">
									Your content comes pre-formatted in the editor—just export and submit!
								</p>
							</div>
						</div>
						<GetStartedButton redirectUrl="/choose" />
					</div>
				</div>
			</div>
			<div className="pt-8">
				<div className="max-w-6xl w-full mx-auto rounded-3xl bg-secondary py-20 px-10 shadow-xl shadow-secondary">
					<div className="text-white md:text-7xl text-5xl text-center max-w-4xl mx-auto font-bold">
						How does our essay writer work?
					</div>
					<div className="grid grid-cols-1 md:grid-cols-3 gap-10 mt-14">
						{[
							'Select your desired writing style and tone for your content, as well as citation formats if necessary.',
							'Input your topic and choose from AI-generated subtitles to craft your essay outline. ',
							'Clarify will effortlessly create  AI-undetectable content using real citations to guarantee accuracy.',
						].map((text, index) => (
							<div key={index} className="flex flex-col gap-4 items-center group">
								<div className="h-14 w-14 rounded-full flex items-center justify-center border-gray-300 border-2 text-white text-2xl font-bold group-hover:bg-white group-hover:text-secondary group-hover:border-white group-hover:scale-125 relative group-hover:bottom-1 transition-all">
									{index + 1}
								</div>
								<div className="text-xl text-center text-gray-300 group-hover:text-white font-semibold">{text}</div>
							</div>
						))}
					</div>
				</div>
			</div> */}
			<div className="max-w-6xl w-full mx-auto flex flex-col gap-12 pb-24">
				<FAQ variant="primary" />
			</div>
			<TryFree />
			<Footer />
		</>
	)
}

export default WriterPage
