import React, { useState } from 'react'
import { Menu } from 'lucide-react'
import { Button } from './ui/button'
import { Link, useHistory } from 'react-router-dom'
import useEnterApp from '../hooks/enterApp'
import LoginModal from '../Components/Modals/Login'
import { useLocalStorage } from '../helpers/utility'
import { useAuth } from '../contexts/Auth'
import GetStartedButton from './get-started'
import Logo from '../Components/Logo'
import useIsPremium from '../hooks/useIsPremium'

const PageHeader: React.FC = () => {
	const [annualPlan] = useLocalStorage('annualPlan', true)
	const [loginModalOpen, setLoginModalOpen] = useState(false)
	const [dropdownOpen, setDropdownOpen] = useState(false)
	const [bannerOpen, setBannerOpen] = useLocalStorage('bannerOpen', false)
	const history = useHistory()
	const { enterApp } = useEnterApp({ history })
	const { currentUser } = useAuth() as any
	const isPremium = useIsPremium()
	const pathname = window.location.pathname

	return (
		<>
			<LoginModal open={loginModalOpen} setModalOpen={setLoginModalOpen} annualPlan={annualPlan} />
			<header className="fixed top-0 z-20 w-full bg-white shadow-md dark:bg-[#162032]">
				{/* {bannerOpen && !isPremium && (
					<div
						className="bg-secondary w-full text-center text-white pl-2 pr-3 py-[10px] font-semibold relative flex md:block items-center cursor-pointer"
						onClick={() => history.push('/pricing')}
					>
						<div>
							<b>Black Friday Special!🎒📝</b> Save up to 30%! Don't miss out – offer ends December 4.{' '}
							<FaExternalLinkAlt className="inline-block mb-1 ml-1" />
						</div>
						<FaX
							size={14}
							className="md:absolute md:right-4 md:top-4 w-8 ml-3 md:ml-0"
							onClick={(e) => {
								e.stopPropagation()
								setBannerOpen(false)
							}}
						/>
					</div>
				)} */}
				<nav className="mx-auto flex w-full max-w-screen-xl flex-row flex-nowrap items-center justify-between p-4">
					<div className="flex flex-1 gap-10">
						<Link to="/" className="flex flex-row items-center gap-1 text-secondary">
							<Logo />
						</Link>
						<div className="hidden flex-row items-center gap-8 lg:flex">
							<Link
								to="/ai-humanizer"
								className={pathname.includes('ai-humanizer') ? 'font-semibold text-primary-700' : 'font-medium'}
							>
								AI Humanizer
							</Link>
							<Link
								to="/ai-detector"
								className={pathname.includes('ai-detector') ? 'font-semibold text-primary-700' : 'font-medium'}
							>
								AI Detector
							</Link>
							<Link
								to="/writer"
								className={pathname.includes('writer') ? 'font-semibold text-primary-700' : 'font-medium'}
							>
								AI Writer
							</Link>
							<Link
								to="/pricing"
								className={pathname.includes('pricing') ? 'font-semibold text-primary-700' : 'font-medium'}
							>
								Pricing
							</Link>
						</div>
					</div>
					<div className="hidden flex-1 flex-row items-center justify-end gap-3 lg:flex">
						{currentUser ? (
							<Button
								onClick={() => {
									enterApp()
								}}
							>
								Launch App
							</Button>
						) : (
							<>
								<Button
									variant="ghost"
									onClick={() => {
										setLoginModalOpen(true)
									}}
								>
									Log in
								</Button>
								<GetStartedButton redirectUrl="/choose" cta="Start Writing" justText size="default" preventExitIntent />
							</>
						)}
					</div>
					<div
						className="lg:hidden"
						onClick={() => {
							setDropdownOpen(!dropdownOpen)
						}}
					>
						<Button variant="ghost" size="icon">
							<Menu />
						</Button>
					</div>
				</nav>
				<div className={`overflow-hidden lg:hidden bg-white ${dropdownOpen ? 'h-auto' : 'h-0'}`}>
					<div className="flex flex-col items-center gap-4 pb-3">
						<Link
							to="/ai-humanizer"
							className="transition-all hover:text-gray-900 block text-center"
							onClick={() => {
								setDropdownOpen(false)
							}}
						>
							AI Humanizer
						</Link>
						<Link
							to="/ai-detector"
							className="transition-all hover:text-gray-900 block text-center"
							onClick={() => {
								setDropdownOpen(false)
							}}
						>
							AI Detector
						</Link>
						<Link
							to="/writer"
							className="transition-all hover:text-gray-900 block text-center"
							onClick={() => {
								setDropdownOpen(false)
							}}
						>
							AI Writer
						</Link>
						<Link
							to="/pricing"
							className="transition-all hover:text-gray-900 block text-center"
							onClick={() => {
								setDropdownOpen(false)
							}}
						>
							Pricing
						</Link>
						{currentUser ? (
							<Button
								onClick={() => {
									enterApp()
								}}
							>
								Launch App
							</Button>
						) : (
							<>
								<Button
									variant="ghost"
									onClick={() => {
										setLoginModalOpen(true)
									}}
								>
									Log in
								</Button>
								<GetStartedButton redirectUrl="/choose" cta="Start Writing" justText size="default" />
							</>
						)}
					</div>
				</div>
			</header>
		</>
	)
}

export default PageHeader
