import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { DetectorEditor } from './Humanizer/editor'
import { FEATURE_FLAGS } from '../hooks/featureFlags'

const DetectorPage = () => {
	const sidePanelFF = useFeatureFlagVariantKey(FEATURE_FLAGS.SIDE_PANEL)
	return (
		<div className={`flex flex-col gap-5 ${sidePanelFF === 'test' ? 'md:mt-8 mt-20' : 'mt-20'}`}>
			<div className="flex flex-col">
				<div className="text-3xl font-semibold text-center">AI Detector</div>
				<div className="text-center text-gray-700 max-w-3xl mx-auto">
					To get started, paste your text here and click the button below, or select a sample text above.
				</div>
			</div>
			<div className="flex justify-center z-10 max-w-6xl mx-auto w-full pb-24 md:pb-0">
				<DetectorEditor />
			</div>
			<div className="w-screen h-screen fixed bg-gradient-to-b from-white to-[#3b82f6] top-0 opacity-15" />
		</div>
	)
}

export default DetectorPage
