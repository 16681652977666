import { Minus, Plus } from 'lucide-react'
import { primaryFaqs, detectorFaqs, humanizerFaqs } from '../lib/faqs'
import { useState } from 'react'

const FAQItem = ({ question, answer }: { question: string; answer: string }) => {
	const [isOpen, setIsOpen] = useState(false)
	return (
		<div className="p-4 border-b flex flex-col gap-2">
			<div className="flex items-center justify-between gap-2 cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
				<h4 className="text-lg font-normal !text-secondary">{question}</h4>
				{isOpen ? <Minus size={20} /> : <Plus size={20} />}
			</div>
			{isOpen && <div className="text-gray-700">{answer}</div>}
		</div>
	)
}

const FAQ = ({ variant = 'humanizer' }: { variant?: 'detector' | 'humanizer' | 'primary' }) => {
	const variantFaq = {
		primary: primaryFaqs,
		humanizer: humanizerFaqs,
		detector: detectorFaqs,
	}[variant]
	const desc = {
		primary: 'Find answers to commonly asked questions about Clarify.',
		humanizer: 'Questions about our AI Humanizer? Find answers here.',
		detector: 'Find answers to commonly asked questions about the AI Detector.',
	}[variant]
	return (
		<div className="mt-24 md:mt-30">
			<div className="mx-auto max-w-4xl text-center">
				{/* <Badge
					size="lg"
					className="gap-2 font-bold uppercase bg-gradient-to-r from-purple-200 to-purple-400 text-purple-800"
				>
					<CircleHelpIcon /> Support
				</Badge> */}
				<h2 className="mt-6 text-4xl sm:text-5xl/tight text-gray-900">Frequently Asked Questions</h2>
				<p className="mt-6 text-xl">{desc}</p>
			</div>
			<div className="mt-10 flex flex-col gap-6">
				{variantFaq.map((faq, index) => (
					<FAQItem key={index} question={faq.question} answer={faq.answer} />
				))}
			</div>
		</div>
	)
}

export default FAQ
