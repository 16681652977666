import { useSelector } from 'react-redux'
import { selectPaddle, selectUser } from '../redux/systemSlice'
import { usePricing } from './featureFlags'
import { useState } from 'react'

const useCheckout = () => {
	const [loading, setLoading] = useState(false)
	const paddle = useSelector(selectPaddle)
	const user = useSelector(selectUser)

	const checkout = async (annual: boolean, freeTrial?: boolean) => {
		setLoading(true)
		if (!user) return
		if (paddle) {
			paddle.Checkout.open({
				items: [
					{
						priceId: annual
							? freeTrial
								? 'pri_01jj4zz6vtj2ww0037mpggbwpj'
								: 'pri_01jj5aekg1s9en99yf70d83jan'
							: freeTrial
							? 'pri_01jj4zv9m29bgsejjwmw4q2rb4'
							: 'pri_01jj5afednavd54zwtj7t4g08d',
						quantity: 1,
					},
					// {
					// 	priceId: annual
					// 		? freeTrial
					// 			? 'pri_01jj65qe3ebw0jdvjxtyq0rerj'
					// 			: 'pri_01jj65r3cytdsrwef6hf132et6'
					// 		: freeTrial
					// 		? 'pri_01jj65p74hc52sh9sndw7p1fn5'
					// 		: 'pri_01jj65pqd5kt0y5np47t1b3t36',
					// 	quantity: 1,
					// },
				],
				customData: {
					user_id: user.id,
				},
				settings: {
					successUrl: window.location.href + `/?paddleSuccess=true&showModal=true${annual ? '&annual=true' : ''}`,
					variant: 'one-page',
				},
			})
			setLoading(false)
		}
	}

	return { checkout, loading }
}

export default useCheckout
