import { useSelector } from 'react-redux'
import { selectUser } from '../../redux/systemSlice'
import useIsPremium, { useIsOnFreeTrial } from '../../hooks/useIsPremium'
import useHumanizerCredits from '../../hooks/useHumanizerCredits'
import { Clock } from 'lucide-react'

const CreditsBar = ({ onClick }: { onClick: () => void }) => {
	const { creditsAvailable, startingCredits } = useHumanizerCredits()
	const isPremium = useIsPremium()
	const user = useSelector(selectUser)
	const isOnFreeTrial = useIsOnFreeTrial()

	if (isPremium || !user) {
		return null
	}

	return (
		<div
			className="flex flex-col gap-2 p-2 w-full bg-primary-100 rounded hover:shadow cursor-pointer"
			onClick={onClick}
		>
			<div className="flex justify-between items-center">
				<div className="font-semibold">{isOnFreeTrial ? 'Free Trial' : 'Free Plan'}</div>
				{isOnFreeTrial && (
					<div className="bg-primary-500 text-white px-2 py-1 rounded-xl text-sm font-semibold">
						<div className="flex items-center gap-1">
							<Clock size={14} />
							<div>Trial</div>
						</div>
					</div>
				)}
			</div>
			<div className="flex flex-col gap-2">
				<div className="flex justify-between">
					<div className="text-primary-700 font-semibold text-sm">AI credits: </div>
					<div className="text-primary-700 font-semibold text-sm">
						{creditsAvailable} / {startingCredits}
					</div>
				</div>
				<div className="w-full h-3 bg-white rounded-full">
					<div
						className="h-3 bg-primary-500 rounded-full"
						style={{ width: `${(creditsAvailable / startingCredits) * 100}%` }}
					></div>
				</div>
			</div>
		</div>
	)
}

export default CreditsBar
