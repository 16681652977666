import './humanizer.scss'
import Paragraph from '@tiptap/extension-paragraph'
import Document from '@tiptap/extension-document'
import Placeholder from '@tiptap/extension-placeholder'
import Text from '@tiptap/extension-text'
import { useAtom } from 'jotai'
import React, { useEffect, useMemo, useState } from 'react'
import EditorTopSection from './top-section'
import { MAX_HUMANIZER_WORD_COUNT, editorAtom, editorDefaultValues } from './constants'
import { useHumanizeText, useWordCount } from './hooks'
import { useEditor } from '@tiptap/react'
import UpgradeModals from '../../Components/Modals/Upgrade'
import useIsPremium, { useIsOnFreeTrial } from '../../hooks/useIsPremium'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, Button as LandingPageButton } from '../../components-landing/ui/button'
import { BookOpenCheck, GraduationCap } from 'lucide-react'
import { Bounce, toast } from 'react-toastify'
import CheckForAi from '../../components-landing/check-for-ai'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FaCheck, FaCopy } from 'react-icons/fa6'
import { handleCreditsExceededEvent, zarazLog } from '../../helpers/analytics'
import { useStreamingText } from '../../hooks/stream'
import GetStartedModal from '../../Components/Modals/Login/GetStarted'
import { useAuth } from '../../contexts/Auth'
import useHumanizerCredits from '../../hooks/useHumanizerCredits'
import GoPremium from '../../Components/Modals/GoPremium'
import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'
import { FEATURE_FLAGS } from '../../hooks/featureFlags'
import useLoadingDots from '../../hooks/useLoadingDots'

const sampleText =
	"Global warming refers to the long-term rise in Earth's average temperatures, primarily due to human activities that release greenhouse gases like carbon dioxide, methane, and nitrous oxide into the atmosphere. These gases trap heat from the sun, creating a 'greenhouse effect' that leads to the warming of the planet. The burning of fossil fuels, deforestation, and industrial processes are major contributors to this phenomenon. As global temperatures rise, the effects can be seen in more frequent and severe weather events, such as heatwaves, droughts, hurricanes, and wildfires. Additionally, global warming accelerates the melting of glaciers and polar ice caps, causing sea levels to rise, which threatens coastal communities and ecosystems. Addressing global warming requires a collective effort to reduce emissions, transition to renewable energy sources, and adopt sustainable practices to preserve the planet for future generations."

export function Editor() {
	const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false)
	const [getStartedModalOpen, setGetStartedModalOpen] = useState(false)
	const [loginModalOpen, setLoginModalOpen] = useState(false)
	const [showSignupModal, setShowSignupModal] = useState(false)
	const [goPremiumModalOpen, setGoPremiumModalOpen] = useState(false)
	const [streamText, setStreamText] = useState(false)
	const [showBlur, setShowBlur] = useState(false)
	const { creditsAvailable } = useHumanizerCredits()
	const [copied, setCopied] = useState(false)
	const [editorState, setEditorState] = useAtom(editorAtom)
	const humanize = useHumanizeText({
		onSuccess: () => {
			setStreamText(true)
		},
	})
	const isOnFreeTrial = useIsOnFreeTrial()
	const wordCount = useWordCount(editorState.textInputValue)
	const humanizedWordCount = useWordCount(editorState.humanizedText ?? '')
	const isPremium = useIsPremium()
	const loadingDots = useLoadingDots(editorState.isLoadingHumanizeText)
	const humanizedTextToDisplay = useStreamingText(editorState.humanizedText ?? '', streamText, () => {
		if (blurFF === 'test' && !(isPremium && isOnFreeTrial) && creditsAvailable < wordCount) {
			setStreamText(false)
			setShowBlur(true)
		}
	})
	const blurredTextToDisplay = useStreamingText(
		"Global warming pertains to the sustained increase in the planet's average temperature (Earth's) over time. This phenomenon is primarily driven by human activities; however, natural factors also play a role. Many scientists argue that the consequences of global warming are profound, because they affect ecosystems and weather patterns. Global warming pertains to the sustained increase in the planet's average temperature (Earth's) over time. This phenomenon is primarily driven by human activities; however, natural factors also play a role. Many scientists argue that the consequences of global warming are profound, because they affect ecosystems and weather patterns.",
		showBlur
	)

	const { currentUser } = useAuth() as any
	const posthog = usePostHog()
	const blurFF = useFeatureFlagVariantKey(FEATURE_FLAGS.BLUR)
	const pathname = window.location.pathname

	useEffect(() => {
		setEditorState({
			...editorState,
			aiDetectionScore: undefined,
			previousHumanization: undefined,
		})
	}, [editorState.textInputValue])

	useEffect(() => {
		setEditorState({
			...editorState,
			humanizedText: undefined,
		})
	}, [])

	useEffect(() => {
		if (copied) {
			const timeoutId = setTimeout(() => {
				setCopied(false)
			}, 2000)
			return () => clearTimeout(timeoutId)
		}
	}, [copied])

	const openModalWithDelay = (setModalOpenFunction: (open: boolean) => void, callback?: () => void) => {
		setEditorState((editorState) => ({
			...editorState,
			isLoadingHumanizeText: true,
		}))
		setTimeout(() => {
			setEditorState((editorState) => ({
				...editorState,
				isLoadingHumanizeText: false,
			}))
			setModalOpenFunction(true)
			if (callback) callback()
		}, 500)
	}

	const _humanize = () => {
		if (wordCount < 30) {
			toast.error('Please enter at least 30 words.', {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
				transition: Bounce,
			})
			return
		}
		setShowBlur(false)
		posthog.capture('humanize', {
			num_words: wordCount,
		})
		if (blurFF === 'test' && creditsAvailable > 10) {
			humanize(false)
			return
		}
		if (!currentUser) {
			openModalWithDelay(setGetStartedModalOpen)
			return
		}

		if (isOnFreeTrial && wordCount > creditsAvailable) {
			openModalWithDelay(setGoPremiumModalOpen)
			posthog.capture('free-trial-credits-exceeded')
			return
		}

		if (isPremium || wordCount <= creditsAvailable) {
			humanize(false)
		} else {
			posthog.capture('credits-exceeded')
			openModalWithDelay(setSubscriptionModalOpen, () => {
				zarazLog('credits-exceeded')
				handleCreditsExceededEvent()
			})
		}
	}

	const accountModals = useMemo(
		() => (
			<>
				<UpgradeModals
					subscriptionModalOpen={subscriptionModalOpen}
					loginModalOpen={loginModalOpen}
					setSubscriptionModalOpen={(open: boolean) => {
						setSubscriptionModalOpen(open)
					}}
					setLoginModalOpen={(open: boolean) => {
						setLoginModalOpen(open)
					}}
					premiumPath={false}
					noMoreCredits
					loginVariant={showSignupModal ? 'signup' : 'login'}
				/>
				<GetStartedModal
					setModalOpen={setGetStartedModalOpen}
					modalOpen={getStartedModalOpen}
					signInWithEmail={() => {
						setLoginModalOpen(true)
						setGetStartedModalOpen(false)
						setShowSignupModal(true)
					}}
				/>
				<GoPremium open={goPremiumModalOpen} setModalOpen={setGoPremiumModalOpen} />
			</>
		),
		[subscriptionModalOpen, loginModalOpen, getStartedModalOpen, goPremiumModalOpen, showSignupModal]
	)

	const copyButton = useMemo(() => {
		return (
			<CopyToClipboard text={editorState.humanizedText ?? ''} onCopy={() => setCopied(true)}>
				<div className={`${humanizedWordCount === 0 ? 'pointer-events-none' : 'cursor-pointer'}`}>
					<span className="relative">
						<FaCheck
							className={`transition-opacity text-xl top-[-10px] absolute right-[-12px] ${
								copied ? 'opacity-100' : 'opacity-0'
							}`}
						/>
						<FaCopy
							className={`transition-opacity top-[-10px] absolute right-[-12px] text-2xl ${
								copied ? 'opacity-0' : 'opacity-100'
							}`}
						/>
					</span>
				</div>
			</CopyToClipboard>
		)
	}, [copied, editorState.humanizedText, wordCount])

	const humanizedTextToDisplayEl = ({ text }: { text: string }) => {
		return (
			<div>
				{text.split('\n').map((line, index) => (
					<React.Fragment key={index}>
						{line}
						<br />
					</React.Fragment>
				))}
			</div>
		)
	}

	return (
		<>
			{accountModals}
			<div className="max-h-[650px] min-h-[650px] bg-white rounded-xl border border-gray-300 shadow-lg flex flex-col w-full">
				<EditorTopSection />
				<div className="grid md:grid-cols-2 grow md:h-[580px] h-[680px]">
					<div className="border-r-2 border-gray-300 h-full flex flex-col min-h-0 relative">
						{editorState.textInputValue.length === 0 && (
							<div className="absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-full flex gap-2">
								{pathname !== '/humanizer' && (
									<LandingPageButton
										variant="outline"
										onClick={() => setEditorState({ ...editorState, textInputValue: sampleText })}
									>
										Try sample text
									</LandingPageButton>
								)}
								<LandingPageButton
									variant="outline"
									onClick={() =>
										navigator.clipboard
											.readText()
											.then((text) => setEditorState({ ...editorState, textInputValue: text }))
									}
								>
									Paste text
								</LandingPageButton>
							</div>
						)}
						<textarea
							placeholder="Type your text here..."
							className="w-full resize-none outline-none p-4 grow overflow-y-auto rounded-bl-xl leading-relaxed min-h-60"
							onChange={(e) => {
								setEditorState({ ...editorState, textInputValue: e.target.value })
							}}
							value={editorState.textInputValue}
						/>
						<div className="sticky bottom-0 mx-4 flex flex-row flex-wrap items-center justify-between bg-white py-4 gap-3">
							{currentUser ? (
								<div className={`font-semibold ${wordCount > MAX_HUMANIZER_WORD_COUNT ? 'text-danger-default' : ''}`}>
									{wordCount} / {MAX_HUMANIZER_WORD_COUNT} Words
								</div>
							) : (
								<div className="font-semibold">{wordCount} Words</div>
							)}
							<div className="flex flex-row items-center gap-2">
								<LandingPageButton
									variant="shadow"
									className="text-white"
									onClick={_humanize}
									disabled={
										editorState.isLoadingHumanizeText ||
										wordCount > MAX_HUMANIZER_WORD_COUNT ||
										editorState.aiDetectionScoreLoading
									}
								>
									<BookOpenCheck className="mr-2" /> {editorState.isLoadingHumanizeText ? 'Humanizing' : 'Humanize'}
								</LandingPageButton>
								<CheckForAi text={editorState.textInputValue} onHumanizeClick={_humanize} />
							</div>
						</div>
					</div>
					<div className="flex flex-col h-full min-h-0 border-t border-gray-500 md:border-t-0">
						<div className="grow bg-white p-4 pt-3 rounded-br-xl overflow-y-auto leading-relaxed min-h-60">
							{editorState.humanizedText ? (
								humanizedTextToDisplayEl({ text: humanizedTextToDisplay })
							) : (
								<span className="text-gray-600">
									{editorState.isLoadingHumanizeText
										? 'Loading' + loadingDots
										: 'Your humanized text will appear here.'}
								</span>
							)}
							{blurFF === 'test' && showBlur && !streamText && creditsAvailable === 0 && (
								<div
									className="overflow-hidden relative md:h-96 h-[500px]"
									onClick={() => {
										if (!currentUser) {
											setGetStartedModalOpen(true)
										} else if (isOnFreeTrial) {
											setGoPremiumModalOpen(true)
										} else {
											setSubscriptionModalOpen(true)
										}
									}}
								>
									<div className="blur-sm pl-1">
										<div className="mt-2 h-48 select-none">{blurredTextToDisplay}</div>
									</div>
									<div className="bg-secondary rounded-xl w-full absolute bottom-0 flex items-center justify-center flex-col gap-3 py-9 px-3">
										<div className="text-xl font-semibold text-center text-white">
											{currentUser
												? 'Upgrade to view the full humanized text'
												: 'Sign up to get 300 words humanized for free'}
										</div>
										<div className="text-gray-200 text-center">
											{currentUser
												? 'Unlock more with an upgraded plan—experience our tools with no long-term commitment. Cancel anytime.'
												: 'Get started with a free account to experience our tools with no long-term commitment. Cancel anytime.'}
										</div>
										<Button variant="outline" className="bg-white hover:bg-gray-100">
											{<>{!currentUser ? 'Get started' : 'Upgrade now'}</>}
										</Button>
									</div>
								</div>
							)}
						</div>
						<div className="sticky bottom-0 bg-white flex justify-between p-4 items-center rounded-xl">
							<div className="font-semibold">{humanizedWordCount} Words</div>
							<div className="flex gap-2">
								{editorState.humanizedText && (
									<CheckForAi
										text={editorState.humanizedText}
										label="Check result for AI"
										humanized
										onHumanizeClick={_humanize}
									/>
								)}
								<LandingPageButton
									size="icon"
									variant="ghost"
									onClick={() => navigator.clipboard.writeText(editorState.humanizedText ?? '')}
									disabled={!editorState.humanizedText}
								>
									{copyButton}
								</LandingPageButton>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export function DetectorEditor() {
	const [editorState, setEditorState] = useAtom(editorAtom)
	const wordCount = useWordCount(editorState.textInputValue)
	const location = useLocation()
	const history = useHistory()

	useEffect(() => {
		setEditorState({
			...editorState,
			aiDetectionScore: undefined,
			previousHumanization: undefined,
		})
	}, [editorState.textInputValue])

	const editor = useEditor(
		{
			extensions: [
				Document,
				Text,
				Paragraph,
				Placeholder.configure({
					placeholder: 'Paste your text here...',
				}),
			],
			onUpdate: ({ editor }) => {
				setEditorState({ ...editorState, textInputValue: editor.getText() })
			},
		},
		[]
	)

	useEffect(() => {
		if (!editor) return
		if (editorState.textInputValue !== editor?.getText()) {
			editor.commands.setContent(editorState.textInputValue, false, {
				preserveWhitespace: 'full',
			})
		}
	}, [editorState.textInputValue, editor])

	useEffect(() => {
		setEditorState(editorDefaultValues)
		editor?.commands.setContent(editorDefaultValues.textInputValue, false, {
			preserveWhitespace: 'full',
		})
	}, [location.pathname])

	return (
		<div className="flex flex-col gap-4  w-full min-h-[500px] shadow-lg border rounded-xl border-gray-400 p-4 bg-white items-start">
			<div className="grid grid-cols-2 md:grid-cols-4 gap-2 overflow-x-auto md:w-auto w-full">
				{[
					{
						name: 'ChatGPT',
						text: "Global warming is a pressing environmental issue characterized by the increase in Earth's average temperature due to the release of greenhouse gases from human activities such as fossil fuel use, deforestation, and industrial processes. This phenomenon has far-reaching consequences, including rising sea levels, extreme weather events, and disruptions to ecosystems and biodiversity. The urgency of addressing global warming requires concerted efforts from individuals, governments, and industries to reduce carbon emissions, invest in renewable energy sources, and implement sustainable practices to mitigate the impacts of a changing climate on our planet.",
					},
					{
						name: 'GPT-4o',
						text: "Global warming refers to the long-term increase in Earth's average surface temperature due to human activities, primarily the emission of greenhouse gases such as carbon dioxide, methane, and nitrous oxide. These emissions result from the burning of fossil fuels, deforestation, industrial processes, and agricultural practices, which trap heat in the atmosphere and lead to the greenhouse effect. This warming trend has profound implications for the planet, including the melting of polar ice caps, rising sea levels, more frequent and severe weather events, shifts in ecosystems and wildlife distributions, and challenges for agriculture and water resources. Addressing global warming requires global cooperation and the implementation of sustainable practices, renewable energy sources, and policy measures aimed at reducing greenhouse gas emissions and adapting to inevitable climate changes.",
					},
					{
						name: 'Human',
						text: "Global warming occurs when CO2 and other pollutants have accumulate in the atmosphere and capture sunlight and solar radiation that reflect off Earth's surface. Normally this radiation would disappear into space. However these pollutants which persist for years to centuries in the atmosphere, capture heat, resulting in warmer planet. These heat-retaining pollutants—such as carbon dioxide, methane, nitrous oxide, water vapor, and synthetic gases—are referred to as greenhouse gases. Their effect was termed the 'greenhouse effect.",
					},
					{
						name: 'Human + AI',
						text: 'Global warming happens when carbon dioxide (CO2) and other air pollutants accumulate in the atmosphere, absorbing sunlight and solar radiation that reflect off the earth’s surface. Under normal circumstances, this radiation would escape into space, but these pollutants, which can remain in the atmosphere for years or even centuries, trap heat and lead to an increase in the planet’s temperature. These heat-trapping pollutants—such as carbon dioxide, methane, nitrous oxide, water vapor, and synthetic fluorinated gases—are known as greenhouse gases, and their collective impact is referred to as the greenhouse effect.',
					},
				].map((source, index) => (
					<LandingPageButton
						key={index}
						variant="outline"
						onClick={() => {
							setEditorState({ ...editorState, textInputValue: source.text })
						}}
					>
						{source.name}
					</LandingPageButton>
				))}
			</div>
			<textarea
				placeholder="Type your text here..."
				className="w-full resize-none outline-none p-4 grow overflow-y-auto rounded-xl leading-relaxed border border-gray-400"
				onChange={(e) => {
					setEditorState({ ...editorState, textInputValue: e.target.value })
				}}
				value={editorState.textInputValue}
			/>
			<div className="flex justify-between w-full items-center">
				<CheckForAi
					text={editorState.textInputValue}
					emphasizeBtn
					onHumanizeClick={() => {
						history.push('/humanizer')
					}}
				/>
				<div className="font-semibold">{wordCount}/2500 Words</div>
			</div>
		</div>
	)
}
