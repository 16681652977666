import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '../../lib/utils'

const buttonVariants = cva(
	'inline-flex items-center justify-center whitespace-nowrap text-base font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
	{
		variants: {
			variant: {
				default:
					'bg-[linear-gradient(to_bottom,#638deb,#346eed)] text-white hover:bg-[linear-gradient(to_bottom,#6892ed,#4377e8)] transition-all',
				shadow:
					'bg-secondary text-secondary-foreground hover:bg-secondary/90 shadow-lg shadow-blue-500/50 hover:shadow-md',
				destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
				outline: 'border border-input bg-background hover:bg-gray-200 hover:text-accent-foreground',
				secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
				ghost: 'hover:bg-gray-100 hover:text-accent-foreground',
				link: 'text-secondary underline-offset-4 hover:underline',
			},
			size: {
				default: 'h-10 px-4 py-2 rounded',
				sm: 'h-9 px-3',
				lg: 'h-11 rounded px-4',
				xl: 'h-14 rounded px-5 text-lg',
				icon: 'h-10 w-10',
			},
			rounded: {
				true: 'rounded-full',
				false: null,
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
			rounded: false,
		},
	}
)

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean
	rounded?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, rounded, asChild = false, ...props }, ref) => {
		const Comp = asChild ? Slot : 'button'
		return <Comp className={cn(buttonVariants({ variant, size, rounded, className }))} ref={ref} {...props} />
	}
)
Button.displayName = 'Button'

export { Button, buttonVariants }
