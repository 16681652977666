import { useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useAuth } from '../contexts/Auth'
import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { FEATURE_FLAGS } from './featureFlags'
import { useIsOnFreeTrial } from './useIsPremium'

const useHumanizerCredits = () => {
	const { currentUser } = useAuth() as any
	const isOnFreeTrial = useIsOnFreeTrial()
	const [cookies, setCookies] = useCookies(['creditsConsumed'])
	const [consumed, setConsumed] = useState<number>(() => cookies.creditsConsumed ?? 0)
	const sidePanelFF = useFeatureFlagVariantKey(FEATURE_FLAGS.SIDE_PANEL)

	// Update localStorage whenever 'consumed' changes
	useEffect(() => {
		setCookies('creditsConsumed', consumed)
	}, [consumed, setCookies])

	// Use functional updates to avoid stale state
	const consumeCredits = (numCredits: number) => {
		setConsumed((prev) => prev + numCredits)
	}

	const addCredits = (numCredits: number) => {
		setConsumed((prev) => prev - numCredits)
	}

	const startingCredits = isOnFreeTrial ? 600 : 300

	const creditsAvailable = useMemo(() => {
		if (isOnFreeTrial) {
			return startingCredits + 300 - (cookies.creditsConsumed ?? 0)
		}
		if (currentUser) {
			return startingCredits - (cookies.creditsConsumed ?? 0)
		}
		return 0
	}, [cookies.creditsConsumed, isOnFreeTrial, currentUser, startingCredits])

	return {
		creditsConsumed: consumed,
		creditsAvailable:
			sidePanelFF === 'test' ? Math.min(startingCredits, Math.max(0, creditsAvailable)) : Math.max(0, creditsAvailable),
		startingCredits,
		consumeCredits,
		addCredits,
	}
}

export default useHumanizerCredits
