import { Carousel, CarouselContent, CarouselItem } from './ui/carousel'
import AutoScroll from 'embla-carousel-auto-scroll'

const reviews = [
	{
		text: 'I wasted so much time looking for a good humanizer before I found Clarify. It makes my writing process so straightforward and efficient now knowing that I don’t have to worry about AI detectors anymore.',
		user: {
			name: 'Sarah W.',
		},
	},
	{
		text: 'Clarify helps me write my essays so much faster, I can generate and humanize an entire essay in seconds. I don’t spend hours and hours writing my essays and assignments anymore!',
		user: {
			name: 'Alex H.',
		},
	},
	{
		text: "Clarify's AI Humanizer was very friendly and had easy to use options to help me with my essays!",
		user: {
			name: 'Jordan B.',
		},
	},
	{
		text: "With Clarify, I don't have to worry about my essays being detected by AI detectors. It's a huge relief!",
		user: {
			name: 'Emily S.',
		},
	},
	{
		text: 'I was skeptical at first, but Clarify has made my writing process so much easier. I can generate essays in seconds and they sound completely human written!',
		user: {
			name: 'Michael C.',
		},
	},
	{
		text: "Clarify has been a game changer for me. I can write essays in half the time and they're completely undetectable by AI detectors.",
		user: {
			name: 'Jessica M.',
		},
	},
]

const Reviews = () => {
	return (
		<>
			<div className="mx-auto max-w-4xl text-center">
				{/* <Badge
					size="lg"
					className="gap-2 font-bold uppercase text-amber-800 bg-gradient-to-r from-yellow-200 to-orange-400"
				>
					<Star /> Reviews
				</Badge> */}
				<h2 className="mt-6 text-4xl sm:text-5xl/tight text-gray-900">
					Why People Love <span className="text-primary-600">Clarify</span>
				</h2>
				<p className="mt-6 text-xl">
					Generate essays, research papers, articles and other types of content within seconds with our AI Humanizer—all
					completely AI-undetectable.
				</p>
			</div>
			{/* <div className="mt-10 grid grid-cols-1 gap-8 sm:grid-cols-3">
						<div className="aspect-[9/16] max-h-[80vh] w-full bg-slate-200 dark:bg-slate-700"></div>
						<div className="aspect-[9/16] max-h-[80vh] w-full bg-slate-200 dark:bg-slate-700"></div>
						<div className="aspect-[9/16] max-h-[80vh] w-full bg-slate-200 dark:bg-slate-700"></div>
					</div> */}
			<div className="mt-4">
				<Carousel
					opts={{
						align: 'start',
						loop: true,
					}}
					plugins={[AutoScroll({ startDelay: 0, speed: 1, stopOnInteraction: false })]}
					className="[mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]"
				>
					<CarouselContent>
						{reviews.map((review, index) => (
							<CarouselItem className="lg::basis-1/5 basis-1/2 sm:basis-1/3 md:basis-1/4 min-w-72">
								<div className="rounded-2xl border bg-card p-6 shadow-lg xl:col-span-1 bg-white">
									<div className="flex flex-row items-center gap-2">
										<div className="w-8 h-8 text-white rounded-full flex items-center justify-center bg-purple-600">
											{review.user.name[0]}
										</div>
										<div className="font-semibold text-slate-900 dark:text-slate-100">{review.user.name}</div>
									</div>
									<blockquote className="text-lg text-slate-900 dark:text-slate-100 sm:text-xl mt-4">
										{review.text}
									</blockquote>
								</div>
							</CarouselItem>
						))}
					</CarouselContent>
				</Carousel>
			</div>
		</>
	)
}
export default Reviews
