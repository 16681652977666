import { BadgeDollarSign, FastForward, FileCheck2, MousePointerClick, UserRoundPen } from 'lucide-react'
import { Button } from '../components-landing/ui/button'
import { Link } from 'react-router-dom'
import FAQ from '../components-landing/faq'
import TryFree from '../components-landing/commons/try-free'
import { useEffect } from 'react'
import Footer from '../Components/Footer'
import { DetectorEditor } from './Humanizer/editor'
import { useInView } from 'react-intersection-observer'
import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'
import { zarazLog } from '../helpers/analytics'
import { Helmet } from 'react-helmet-async'
import Navbar from '../Components/Navbar/Navbar'
import { FEATURE_FLAGS } from '../hooks/featureFlags'
import { DetectorDisplaySection, DetectorSection, TurnitinBadge } from './Home'
import HumanizerPageUI from '../assets/images/humanizerpageui.png'

export const LookingToBypass = () => {
	return (
		<div className="relative overflow-hidden w-full mt-12 shadow-lg bg-secondary max-w-7xl mx-auto rounded-3xl pt-12 px-2 md:px-0">
			<div className="flex flex-col items-center gap-8 max-w-4xl mx-auto">
				<div className="text-4xl font-semibold text-center z-10 text-white">
					Looking to bypass AI detectors? <br />
					Humanize your content with Clarify!
				</div>
				<div className="text-center z-10 text-white">
					Our AI Humanizer creates human-sounding content and avoids many of the common AI phrases and words that can
					make content appear AI-generated. Use our humanizer to avoid detection from Turnitin, Originality, and other
					popular AI detectors on the market.
				</div>
				<Link to="/ai-humanizer" className="z-10">
					<Button variant="outline" className="text-xl py-6 text-white">
						<MousePointerClick className="mr-2" />
						Humanize Now
					</Button>
				</Link>
				<img src={HumanizerPageUI} className="w-full h-full object-cover max-w-xl" />
			</div>
			{/* <div className="flex flex-col items-center">
		<div
			className="w-full h-[120%] absolute opacity-50 bottom-[-300px]"
			style={{ background: 'radial-gradient(circle at center, #3b82f6 0%, rgb(15 23 42) 50%)' }}
		/>
	</div> */}
		</div>
	)
}

const DetectorLandingPage = () => {
	const sidePanelFF = useFeatureFlagVariantKey(FEATURE_FLAGS.SIDE_PANEL)
	const turnitinBadge = useFeatureFlagVariantKey(FEATURE_FLAGS.TURNITIN_BADGE)

	const posthog = usePostHog()
	const { ref, inView } = useInView({
		/* Optional options */
		threshold: 0,
	})
	useEffect(() => {
		posthog.capture('page-viewed', { page: 'detector' })
		window.scrollTo(0, 0)
		zarazLog('PageView')
	}, [])

	return (
		<>
			<Helmet>
				<title>AI Detector | 99% Accurate Detector</title>
				<meta
					name="description"
					content="Our AI detector is 99% accurate and completely free. Use it to determine if your content passes AI checks from leading detection tools, including Turnitin."
				/>
				<meta name="keywords" content="AI Detector, AI Content Detector, Turnitin Detector" />
			</Helmet>
			{sidePanelFF === 'test' && <Navbar />}

			<div className="pt-32 mx-auto flex flex-col gap-10  relative">
				<div className=" w-full mx-auto flex flex-col gap-12">
					<div className="flex flex-col items-center gap-2 w-full mx-auto relative">
						<div className="w-full h-full bg-gradient-to-b from-white to-[#346eed]  absolute bottom-[250px] opacity-20 z-[-10] md:block hidden" />
						<div className="flex flex-col items-center gap-2 max-w-4xl mx-auto">
							{turnitinBadge === 'test' && <TurnitinBadge />}
							<div className="md:text-6xl text-4xl font-semibold text-center">
								Our AI detector that's both <span className="text-secondary">99% accurate</span> and completely
								<span className="text-secondary"> free.</span>
							</div>
							<div className="text-center max-w-3xl my-4 text-lg">
								Our AI content detector ranks among the most accurate in the industry. Use it to determine if your
								content passes AI checks from leading detection tools, including Turnitin.
							</div>
						</div>
						<div
							className={`transform transition-all duration-700 w-full max-w-6xl mx-auto ${
								inView ? 'opacity-100 scale-100' : 'opacity-0 scale-90'
							}`}
							ref={ref}
						>
							<DetectorEditor />
						</div>
					</div>
					<div className="my-12">
						<DetectorDisplaySection bg="bg-white" />
					</div>
					<DetectorSection />
					<div className="max-w-3xl flex flex-col gap-2 items-center mt-12 mx-auto">
						<div className="mt-6 md:text-5xl text-4xl text-gray-900 font-semibold text-center">
							Bypass <span className="text-primary-600">TurnItIn</span> and other leading AI Detectors with Clarify AI.
						</div>
						<p className="mt-6 text-xl leading-relaxed text-gray-700 text-center">
							Instantly detect any AI-authored text by using our free AI detector.
						</p>
					</div>
					<div className="mx-auto max-w-6xl grid md:grid-cols-2 gap-4">
						{[
							{
								icon: <FileCheck2 size={20} />,
								title: 'Accurate Detection',
								description:
									'Detect AI-authored text instantly with our free tool and make changes to ensure your content passes AI detectors.',
							},
							{
								icon: <BadgeDollarSign size={20} />,
								title: 'Completely Free',
								description: 'Enjoy unrestricted access to our AI detector at no charge, with no subscription.',
							},
							{
								icon: <UserRoundPen size={20} />,
								title: 'Humanization',
								description:
									'Is your content being flagged as AI-written? Use our humanizer to ensure it bypasses all detection tools.',
							},
							{
								icon: <FastForward size={20} />,
								title: 'Lightning-Fast',
								description:
									'Run your detection in seconds, not minutes. Our AI content detector quickly identifies and flags any AI-written content.',
							},
						].map((feature, index) => (
							<div key={index} className="flex flex-col gap-3 px-3 py-6 rounded-xl border-gray-300">
								<div className="text-lg flex items-center gap-2 font-semibold">
									<div className="p-2 bg-secondary rounded-full text-white">{feature.icon}</div> {feature.title}
								</div>
								<div className="text-gray-800">{feature.description}</div>
							</div>
						))}
					</div>
					{/* <div className="mx-auto max-w-6xl grid md:grid-cols-2 gap-4">
						{[
							{
								icon: <FileCheck2 size={20} />,
								title: 'Essays',
								description:
									'Need essay help? Use Clarify to generate an essay or research paper in seconds, complete with in-text citations from real articles, journals, and websites.',
							},
							{
								icon: <LibraryBig size={20} />,
								title: 'Homework',
								description:
									'Ace your assignments effortlessly. Our AI tool can help you finish your homework with original, teacher-approved content.',
							},
							{
								icon: <Newspaper size={20} />,
								title: 'Blogs',
								description: 'Use our AI tool to craft human-sounding content that engages readers and ranks well.',
							},
							{
								icon: <Building size={20} />,
								title: 'Work',
								description:
									'Accelerate your writing speed and boost your productivity—Clarify generates professional, undetectable content across a wide range of industries.',
							},
						].map((feature, index) => (
							<div key={index} className="flex flex-col gap-3 px-3 py-6 rounded-xl border-gray-300">
								<div className="text-lg flex items-center gap-2 font-semibold">
									<div className="p-2 bg-secondary rounded-full text-white">{feature.icon}</div> {feature.title}
								</div>
								<div className="text-gray-800">{feature.description}</div>
							</div>
						))}
					</div> */}
					<LookingToBypass />
					<div className=" px-1">
						<div className="max-w-6xl mx-auto">
							<FAQ variant="humanizer" />
						</div>
					</div>
					<div className="mt-24">
						<TryFree />
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default DetectorLandingPage
