import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CONSTANTS, DOC_TEMPLATES } from '../../constants'
import TextareaAutosize from 'react-textarea-autosize'
import { selectDocumentState, setContent, setDrafts, setTitle } from '../../redux/docSlice'
import './_Create.scss'
import { DocumentState } from '../../redux/types'
import DocumentWrapper from '../../Components/DocumentWrapper'
import Setup from './Setup'
import ErrorModal from '../../Components/Modals/Error'
import { useAtom } from 'jotai'
import { accountModalAtom } from './Create'
import { selectGraderModeOn, selectUser } from '../../redux/systemSlice'
import { Skeleton } from '@mui/material'
import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { FEATURE_FLAGS } from '../../hooks/featureFlags'

type CreateProps = {
	type: keyof typeof DOC_TEMPLATES | undefined
	docID: string
}

const freeUserLoadingSteps = ['Creating outline', 'Writing body', 'Formatting']

function Essay(props: CreateProps) {
	const dispatch = useDispatch()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, props.docID))
	const [topicInputFocused, setTopicInputFocused] = useState<boolean>(false)
	const [loadingDots, setLoadingDots] = useState<string>('.')
	const [loadingStatusIndex, setLoadingStatusIndex] = useState<number>(0)
	const [errorModalOpen, setErrorModalOpen] = useState(false)
	const [, setAccountModalState] = useAtom(accountModalAtom)
	const documentRef = useRef<HTMLDivElement>(null)
	const headerRef = useRef<HTMLDivElement>(null)
	const graderModeOn = useSelector(selectGraderModeOn)
	const user = useSelector(selectUser)
	const topicInputRef = useRef(null)
	const sidePanelFF = useFeatureFlagVariantKey(FEATURE_FLAGS.SIDE_PANEL)

	useEffect(() => {
		let intervalId: any

		if (documentState && (documentState.isHumanizing || documentState.isGenerating)) {
			intervalId = setInterval(() => {
				// Update dots: cycle from '' -> '.' -> '..' -> '...'
				setLoadingDots((prevDots) => (prevDots.length < 3 ? prevDots + '.' : ''))
			}, 500) // Update every 500 milliseconds
		} else {
			setLoadingDots('') // Reset dots when loading is false
		}

		return () => clearInterval(intervalId) // Cleanup on unmount or loading changes
	}, [documentState?.isHumanizing, documentState?.isGenerating])

	const loadingSteps = useMemo(() => {
		if (user.planId) {
			return ['Finding sources', 'Creating outline', 'Writing body', 'Writing conclusion', 'Formatting']
		} else if (documentState && documentState?.userWriterSelections?.refStyle === null) {
			return freeUserLoadingSteps
		} else {
			return ['Finding sources', ...freeUserLoadingSteps]
		}
	}, [documentState?.userWriterSelections?.refStyle, documentState?.isGenerating, user.planId])

	const intervalLength = useMemo(() => {
		const referencesAddition =
			documentState && documentState?.userWriterSelections.refStyle === null
				? 0
				: documentState?.userWriterSelections?.wordCount > 700
				? 2500
				: 1500
		if (!user.planId) {
			return 1500 + referencesAddition
		} else {
			return Math.ceil((documentState?.userWriterSelections?.wordCount ?? 150) / 200) * 1000 + referencesAddition
		}
	}, [documentState?.userWriterSelections?.refStyle, documentState?.userWriterSelections?.wordCount, user.planId])

	useEffect(() => {
		if (documentState && !documentState.isGenerating) {
			setLoadingStatusIndex(0)
		}
	}, [documentState?.isGenerating])

	useEffect(() => {
		let intervalId: any

		if (documentState?.isGenerating) {
			intervalId = setInterval(() => {
				setLoadingStatusIndex((prevIndex) => {
					if (prevIndex < loadingSteps.length - 1) {
						return prevIndex + 1
					} else {
						return prevIndex // Stay at the last index
					}
				})
			}, intervalLength)
		} else {
			setLoadingStatusIndex(0) // Reset when not generating
		}

		return () => clearInterval(intervalId) // Cleanup on unmount or loading changes
	}, [documentState?.isGenerating, loadingSteps, intervalLength])

	return (
		<>
			<ErrorModal open={errorModalOpen} closeModal={() => setErrorModalOpen(false)} />
			<div
				id="create"
				className={`${graderModeOn ? 'pt-0 pb-24' : 'py-16'} px-4 md:px-0 md:max-w-4xl md:m-auto relative`}
			>
				{documentState && (
					<Setup
						type={props.type}
						docID={props.docID}
						setSubscriptionModalOpen={(open) =>
							setAccountModalState((prev) => ({ ...prev, subscriptionModalOpen: open }))
						}
					/>
				)}
				{documentState && documentState.setup && documentState.drafts.length > 0 && (
					<>
						<div
							className={`flex gap-3 justify-start mb-4 sticky pb-4 pt-3 z-10 items-center ${
								sidePanelFF === 'test' ? 'bg-gray-50 md:pt-0 -mt-10 top-0' : 'bg-white top-12 md:top-14 md:pt-0'
							}`}
						>
							<div
								className={`${
									documentState.drafts[0].selected || documentState.isGenerating
										? 'bg-gray-900 text-white'
										: 'bg-gray-50 hover:bg-gray-100'
								} rounded-full px-4 py-1 font-semibold cursor-pointer`}
								onClick={() => {
									dispatch(setContent({ docID: props.docID, content: documentState.drafts[0].content }))
									dispatch(
										setDrafts({
											docID: props.docID,
											drafts: [
												{ content: documentState.drafts[0].content, selected: true },
												{ content: documentState.drafts[1].content, selected: false },
											],
										})
									)
								}}
							>
								Draft #1
							</div>
							{documentState?.drafts.length !== 2 || documentState?.isHumanizing ? (
								<Skeleton
									variant="rectangular"
									height={32}
									width={100}
									style={{ borderRadius: '17px', textAlign: 'center', paddingTop: '3px', fontWeight: '600' }}
								>
									Draft #2
								</Skeleton>
							) : (
								<div
									className={`${
										documentState?.drafts.length === 2 && documentState.drafts[1].selected
											? 'bg-gray-900 text-white'
											: sidePanelFF === 'test'
											? 'bg-gray-50 hover:bg-gray-100'
											: 'bg-white hover:bg-gray-100'
									} rounded-full px-4 py-1 font-semibold cursor-pointer`}
									onClick={() => {
										dispatch(setContent({ docID: props.docID, content: documentState.drafts[1].content }))
										dispatch(
											setDrafts({
												docID: props.docID,
												drafts: [
													{ content: documentState.drafts[0].content, selected: false },
													{ content: documentState.drafts[1].content, selected: true },
												],
											})
										)
									}}
								>
									Draft #2
								</div>
							)}
						</div>
					</>
				)}
				{documentState && documentState.setup && (
					<>
						<div className={`headerContainer ${graderModeOn ? 'mt-24' : ''}`} ref={headerRef}>
							<TextareaAutosize
								placeholder="Untitled"
								id="topicHeader"
								className={`pb-2 w-full resize-none outline-none ${sidePanelFF === 'test' ? 'bg-gray-50' : ''} ${
									documentState.title.length > 100 ? 'md:text-3xl text-2xl' : 'md:text-4xl text-3xl'
								} font-medium`}
								ref={topicInputRef}
								onChange={(e) => {
									dispatch(setTitle({ docID: props.docID, title: e.target.value }))
								}}
								onFocus={() => {
									setTopicInputFocused(true)
								}}
								onBlur={() => {
									setTopicInputFocused(false)
								}}
								minRows={1}
								maxRows={topicInputFocused ? undefined : 3}
								maxLength={CONSTANTS.MAX_TOPIC_LENGTH}
								value={documentState.title}
							/>
							{documentState.isGenerating ||
							documentState.isHumanizing ||
							documentState.isTypingEssay ||
							documentState.isTypingAddMore ? (
								<div className="topicDivider oscillating-bar"></div>
							) : (
								<div className="topicDivider dividingBorder"></div>
							)}
						</div>
					</>
				)}
				<div ref={documentRef}>
					{documentState && documentState.isHumanizing && (
						<div className="w-72 absolute bg-white shadow z-10 top-72 left-[calc(50%-130px)] py-2 px-5 border border-gray-300 rounded">
							<h6 className="text-2xl  text-center">Humanizing{loadingDots}</h6>
							<div className="text-gray-600 text-center">This may take up to a minute.</div>
						</div>
					)}
					{documentState && documentState.isGenerating && (
						<>
							<div className="mt-10">
								{[...Array(10)].map((_, index) => (
									<Skeleton key={index} variant="text" width="100%" height={40} animation="wave" />
								))}
							</div>
							<div className="w-72 absolute bg-white shadow z-10 top-72 left-[calc(50%-145px)] py-2 px-5 border border-gray-300 rounded">
								<h6 className="text-2xl  text-center">
									{props.type === DOC_TEMPLATES.Outline ? 'Writing outline' : loadingSteps[loadingStatusIndex]}
									{loadingDots}
								</h6>
								<div className="text-gray-600 text-center">This may take up to a minute.</div>
							</div>
						</>
					)}
					<div className={documentState && documentState.isHumanizing ? 'humanizing' : ''}>
						<DocumentWrapper docID={props.docID} type={props.type} />
					</div>
				</div>

				{/* {documentState?.content && !documentState.isGenerating && documentState.isTypingEssay && (
				<Basic
					onClick={() => {
						if (reader) reader.cancel()
					}}
					type="tertiary"
					CTA={<span>Stop generating</span>}
					className="abortButton"
				/>
			)} */}
			</div>
		</>
	)
}

export default Essay
