import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { Editor } from './Humanizer/editor'
import { FEATURE_FLAGS } from '../hooks/featureFlags'

const HumanizerPage = () => {
	const sidePanelFF = useFeatureFlagVariantKey(FEATURE_FLAGS.SIDE_PANEL)
	return (
		<div className={`flex flex-col gap-5 ${sidePanelFF === 'test' ? 'md:mt-8 mt-20' : 'mt-20'}`}>
			<div className="flex flex-col">
				<div className="text-3xl font-semibold text-center">AI Humanizer</div>
				<div className="text-center text-gray-700 max-w-3xl mx-auto">
					To rewrite or check your text, enter or paste it here and select a button below.
				</div>
			</div>
			<div className="flex justify-center z-10 max-w-6xl mx-auto w-full pb-96 md:pb-24">
				<Editor />
			</div>
			<div className="w-screen h-screen fixed bg-gradient-to-b from-white to-[#513bf6] top-0 opacity-15" />
		</div>
	)
}

export default HumanizerPage
