import { useSelector } from 'react-redux'
import { DocumentState } from '../../redux/types'
import { selectDocumentState } from '../../redux/docSlice'
import { htmlToString } from '../../helpers/utility'
import { useDocId } from '../../hooks/docID'
import { MdOutlineRadar } from 'react-icons/md'
import { usePostHog } from 'posthog-js/react'
import useWriteEssay from '../../hooks/useWriteEssay'
import { HumanizerIntensityLevel } from '../../Pages/Humanizer/constants'
import CheckForAi from '../../components-landing/check-for-ai'

const Detector = () => {
	const docID = useDocId()
	const posthog = usePostHog()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const { humanizeEssay } = useWriteEssay({ docID: docID })

	return (
		<CheckForAi
			text={htmlToString(documentState.content, true)}
			onHumanizeClick={() => {
				humanizeEssay(
					documentState.content,
					documentState.drafts.findIndex((draft: any) => !!draft.selected),
					HumanizerIntensityLevel.HIGH
				)
				posthog.capture('rehumanize-essay')
			}}
			context="writer"
			buttonChildren={
				<div className="flex items-center gap-2 px-4 font-normal">
					<MdOutlineRadar className="text-sm hidden md:block" />
					<div className="block md:hidden">AI Check</div>
					<div className="hidden md:block">Check for AI</div>
				</div>
			}
		/>
	)
}

export default Detector
