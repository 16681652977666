import './_Thanks.scss'
import { useEffect, useMemo, useState } from 'react'
import { usePricing } from '../../hooks/featureFlags'
import { useDispatch, useSelector } from 'react-redux'
import { usePostHog } from 'posthog-js/react'
import { handlePurchaseEvent, zarazLog } from '../../helpers/analytics'
import ThanksModal from './FreeTrial'
import { selectUser } from '../../redux/systemSlice'
import { useLocalStorage } from '../../helpers/utility'
import { useIsOnFreeTrial } from '../../hooks/useIsPremium'

function Thanks({ open }: { open?: boolean }) {
	const [purchaseLogged, setPurchaseLogged] = useState(false)
	const [thanksModalOpen, setThanksModalOpen] = useState(false)
	const [pageRefreshed, setPageRefreshed] = useLocalStorage('succeessfulPaymentReload', false)
	// const { testPaymentModalOpen } = useTestPayment({
	// 	onSuccess: () => {
	// 		setThanksModalOpen(true)
	// 	},
	// })
	const [annualPlan] = useLocalStorage('annualPlan', true)
	const price = usePricing()
	const posthog = usePostHog()
	const user = useSelector(selectUser)
	const isOnFreeTrial = useIsOnFreeTrial()
	const searchParamsString = document.location.search
	const dispatch = useDispatch()

	useEffect(() => {
		if (open && !thanksModalOpen) {
			setThanksModalOpen(true)
		}
	}, [open])

	const searchParams = useMemo(() => {
		let params = new URLSearchParams(searchParamsString)

		return {
			showModal: params.getAll('showModal')[0],
			paddleSuccess: params.getAll('paddleSuccess')[0],
			annual: params.getAll('annual')[0],
			freeTrial: params.getAll('freeTrial')[0],
		}
	}, [searchParamsString])

	useEffect(() => {
		if (searchParams.paddleSuccess && pageRefreshed) {
			setThanksModalOpen(true)
			const newSearchParams = new URLSearchParams(searchParamsString)
			newSearchParams.delete('paddleSuccess')
			const newUrl = window.location.pathname + (newSearchParams.toString() ? '?' + newSearchParams.toString() : '')
			window.history.replaceState(null, '', newUrl)
		}
	}, [searchParams, dispatch])

	useEffect(() => {
		if (searchParams.paddleSuccess && !pageRefreshed) {
			setPageRefreshed(true)
			window.location.reload()
		}
	}, [searchParams, pageRefreshed])

	useEffect(() => {
		if (searchParams.showModal) {
			setThanksModalOpen(true)
		}
	}, [searchParams, dispatch, price])

	useEffect(() => {
		const fullPurchase = !isOnFreeTrial && !!user?.planId
		if (!purchaseLogged && thanksModalOpen && fullPurchase) {
			setPurchaseLogged(true)
			handlePurchaseEvent(80)
			zarazLog('purchase', {
				value: parseFloat(annualPlan ? price.yearlyTotal : price.monthly),
				currency: 'USD',
			})
			posthog?.capture('purchase', {
				price: annualPlan ? price.yearlyTotal : price.monthly,
				plan: annualPlan ? 'annual' : 'monthly',
			})
		} else if (isOnFreeTrial) {
			handlePurchaseEvent(12)
			zarazLog('StartTrial')
		}
	}, [thanksModalOpen, user, price, annualPlan, posthog, purchaseLogged])

	if (!user.uid) {
		return null
	}

	// if (testPaymentModalOpen) {
	// 	return <TestPaymentModal />
	// }

	return (
		<ThanksModal
			open={thanksModalOpen}
			closeModal={() => {
				setThanksModalOpen(false)
				const newSearchParams = new URLSearchParams(searchParamsString)
				newSearchParams.delete('showModal')
				newSearchParams.delete('annual')
				newSearchParams.delete('freeTrial')
				const newUrl = window.location.pathname + (newSearchParams.toString() ? '?' + newSearchParams.toString() : '')
				window.history.replaceState(null, '', newUrl)
			}}
		/>
	)
}

export default Thanks
