import { useHistory } from 'react-router-dom'
import { Button } from '../components-landing/ui/button'
import Footer from '../Components/Footer'
import { Clock, GraduationCap, ListTodo, MousePointerClick, UsersRound } from 'lucide-react'
import Detection from '../assets/images/detection.png'
import Humanizer from '../assets/images/humanization.png'
import SeoWriter from '../assets/images/seowriter.png'
import WritingTools from '../assets/images/writingtools.png'
import { Editor } from './Humanizer/editor'
import { useInView } from 'react-intersection-observer'
import Reviews from '../components-landing/reviews'
import FAQ from '../components-landing/faq'
import TryFree from '../components-landing/commons/try-free'
import { useEffect, useMemo, useState } from 'react'
import UpgradeModals from '../Components/Modals/Upgrade'
import GetStartedModal from '../Components/Modals/Login/GetStarted'
import { useAuth } from '../contexts/Auth'
import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'
import { useLocalStorage } from '../helpers/utility'
import { zarazLog } from '../helpers/analytics'
import { AllInOneTool, DetectorDisplaySection, DetectorSection, HumanizerSection, TurnitinBadge } from './Home'
import { FEATURE_FLAGS } from '../hooks/featureFlags'
import Navbar from '../Components/Navbar/Navbar'
import LogoCarousel from '../components-landing/logo-carousel'

const HumanizerLanding = () => {
	const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false)
	const [getStartedModalOpen, setGetStartedModalOpen] = useState(false)
	const [loginModalOpen, setLoginModalOpen] = useState(false)
	const [bannerOpen] = useLocalStorage('bannerOpen', false)
	const sidePanelFF = useFeatureFlagVariantKey(FEATURE_FLAGS.SIDE_PANEL)
	const turnitinBadge = useFeatureFlagVariantKey(FEATURE_FLAGS.TURNITIN_BADGE)

	const history = useHistory()
	const posthog = usePostHog()
	const { currentUser } = useAuth() as any
	const { ref, inView } = useInView({
		threshold: 0,
	})

	useEffect(() => {
		posthog.capture('page-viewed', { page: 'humanizer' })
		window.scrollTo(0, 0)
		zarazLog('PageView')
	}, [])

	const accountModals = useMemo(
		() => (
			<>
				<UpgradeModals
					subscriptionModalOpen={subscriptionModalOpen}
					loginModalOpen={loginModalOpen}
					setSubscriptionModalOpen={(open: boolean) => {
						setSubscriptionModalOpen(open)
					}}
					setLoginModalOpen={(open: boolean) => {
						setLoginModalOpen(open)
					}}
					premiumPath={true}
					noMoreCredits
				/>
				<GetStartedModal
					setModalOpen={setGetStartedModalOpen}
					modalOpen={getStartedModalOpen}
					signInWithEmail={() => {
						setLoginModalOpen(true)
						setGetStartedModalOpen(false)
					}}
				/>
			</>
		),
		[subscriptionModalOpen, loginModalOpen, getStartedModalOpen]
	)

	return (
		<>
			{sidePanelFF === 'test' && <Navbar />}
			{accountModals}
			<div className="mx-auto flex flex-col ">
				<div className="w-full mx-auto flex flex-col gap-12 pb-24">
					<div className="relative">
						<div className="w-full h-full bg-gradient-to-b from-white to-[#346eed]  absolute bottom-[300px] opacity-20 z-[-10] md:block hidden" />
						<div className={`mx-auto  w-full max-w-screen-xl px-4 md:mt-32 ${bannerOpen ? 'mt-44' : 'mt-24'}`}>
							{turnitinBadge === 'test' && <TurnitinBadge />}
							{/* <div className="text-center font-bold text-purple-600 tracking-wider">AI HUMANIZER</div> */}
							<div className="mx-auto w-full max-w-5xl text-center mt-1">
								<h1 className="md:text-5xl text-4xl leading-tight sm:text-6xl font-[500] text-gray-900">
									Transform robotic AI text into human writing with our AI Humanizer
								</h1>
								<p className="mt-4 text-xl text-gray-900">
									<span className="font-semibold">Join 4M+ writers.</span> Turn AI-written text into natural, human-like
									content with our AI Humanizer. Clarify AI helps you easily bypass AI detectors like Turnitin,
									Originality, and GPTZero, all while enhancing tone and flow.
								</p>
								<Button
									size="xl"
									className="group mt-6 md:text-2xl whitespace-pre-wrap"
									onClick={() => {
										if (!currentUser) {
											setGetStartedModalOpen(true)
										} else {
											history.push('/humanizer')
										}
									}}
								>
									<MousePointerClick className="mr-2" />
									Start Humanizing Now
									<span className="ml-1 text-base text-white transition-all group-hover:text-white">- it's free</span>
								</Button>
							</div>
							<div
								className={`mt-10 hidden md:block transform transition-all duration-700 ${
									inView ? 'opacity-100 scale-100' : 'opacity-0 scale-90'
								}`}
								ref={ref}
							>
								<Editor />
							</div>
							<div className="mt-10 block md:hidden">
								<video
									src="/humanizer.mp4"
									className="rounded-xl shadow"
									autoPlay
									loop
									muted
									playsInline
									controls={false}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="mb-24 mt-6 text-center">
					<div className="mb-4 flex flex-row justify-center gap-2">
						<GraduationCap className="text-slate-500" />
						<h3 className="font-medium ">Used by Writers At Top Colleges and Universities</h3>
					</div>
					<LogoCarousel />
				</div>
				<div className="py-24 bg-primary-50 px-1">
					<div className="max-w-6xl mx-auto flex flex-col items-center gap-2">
						<div className="flex gap-2 text-secondary justify-center items-center">
							{/* <GraduationCap size={48} /> */}
							<div className="text-4xl font-semibold md:text-left text-center">Trusted by Students Globally</div>
						</div>
						<div className="text-center max-w-3xl text-lg">
							Thousands of students and content writers around the world use our AI humanizer to deliver original,
							human-like content that bypasses the leading plagiarism and AI detectors.
						</div>
						<div className="grid md:grid-cols-3 w-full rounded-xl gap-0.5 overflow-hidden mt-8">
							<div className="flex flex-col gap-2 p-4  justify-center items-center">
								<div className="text-3xl font-semibold text-secondary">800,000+</div>
								<div className="text-sm text-gray-700">
									<Clock className="inline-block mr-1" size={15} />
									<div className="inline font-semibold">Hours saved writing</div>
								</div>
							</div>
							<div className="flex flex-col gap-2 p-4 justify-center items-center">
								<div className="text-3xl font-semibold text-secondary">3,000,000+</div>
								<div className="text-sm text-gray-700">
									<UsersRound className="inline-block mr-1" size={15} />
									<div className="inline font-semibold">Writers using Clarify</div>
								</div>
							</div>
							<div className="flex flex-col gap-2 p-4  justify-center items-center">
								<div className="text-3xl font-semibold text-secondary">13,000,000+</div>
								<div className="text-sm text-gray-700">
									<ListTodo className="inline-block mr-1" size={15} />
									<div className="inline font-semibold">AI Humanizations</div>
								</div>
							</div>
						</div>
						{/* <div className="text-success-default text-2xl font-medium w-full text-center mt-8">
							<ThumbsUp className="inline-block mb-1" /> Join the thousands of students and content writers using our AI
							humanizer today!
						</div> */}
					</div>
				</div>
				<div className="py-24 px-1">
					<HumanizerSection />
					<div className="md:mt-36 mt-24">
						<DetectorSection switchOrder />
					</div>
					<div className="md:mt-36 mt-24 mb-12">
						<DetectorDisplaySection />
					</div>
					<AllInOneTool />
				</div>
				<div className="py-24 bg-primary-50 px-1">
					<div className="mx-auto">
						<Reviews />
					</div>
				</div>
				<div className="px-1">
					<div className="max-w-6xl mx-auto">
						<FAQ variant="humanizer" />
					</div>
					<div className="mt-24">
						<TryFree variant="humanizer" />
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default HumanizerLanding
