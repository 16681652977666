import { useSelector } from 'react-redux'
import { selectUser } from '../redux/systemSlice'

const useIsPremium = (): boolean => {
	const user = useSelector(selectUser)
	return !!user?.planId || user?.paddlePlanStatus === 'active' || user?.paddlePlanStatus === 'scheduled_cancellation'
}

export const useIsOnFreeTrial = (): boolean => {
	const user = useSelector(selectUser)
	return !!user?.isOnFreeTrial || user?.paddlePlanStatus === 'trialing'
}

export default useIsPremium
