import { useDispatch, useSelector } from 'react-redux'
import {
	selectUser,
	selectUserLoading,
	selectUserPagesCache,
	selectUserPagesLoading,
	setUserPagesCache,
	setUserPagesLoading,
} from '../../redux/systemSlice'
import { ExistingDoc } from '../UserDocs/UserDocs'
import TextareaAutosize from 'react-textarea-autosize'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { CONSTANTS } from '../../constants'
import { Link } from 'react-router-dom'
import './_Docs.scss'
import Modal from './Modal'
import { useCookies } from 'react-cookie'
import { useAuth } from '../../contexts/Auth'
import * as Sentry from '@sentry/react'
import Loader from '../Loader'

const ExistingDocsModal = ({ isOpen, closeModal }: { isOpen: boolean; closeModal: () => void }) => {
	const [searchInput, setSearchInput] = useState('')
	const [cookies] = useCookies(['docs'])
	const userLoading = useSelector(selectUserLoading)
	const userPagesCache = useSelector(selectUserPagesCache)
	const userPagesLoading = useSelector(selectUserPagesLoading)
	const { currentUser } = useAuth() as any
	const user = useSelector(selectUser)
	const dispatch = useDispatch()

	const getCookieDocuments = useCallback(
		async (existingCookieDocs: any) => {
			dispatch(setUserPagesLoading({ value: true }))
			if (userPagesCache.source === 'cookie' && !userPagesCache.dirty) {
				dispatch(setUserPagesLoading({ value: false }))
				return
			}
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ docs: existingCookieDocs }),
			}
			fetch(process['env']['REACT_APP_API_ROOT'] + '/document/guest-list/', requestOptions)
				.then((res) => res.json())
				.then((result: ExistingDoc[]) => {
					if (result) {
						dispatch(setUserPagesCache({ source: 'cookie', pages: result, dirty: false }))
					}
					dispatch(setUserPagesLoading({ value: false }))
				})
				.catch((e) => {
					Sentry.captureException(e)
					dispatch(setUserPagesLoading({ value: false }))
				})
		},
		[dispatch, userPagesCache]
	)

	const getUserDocuments = useCallback(async () => {
		if (currentUser && user.id) {
			dispatch(setUserPagesLoading({ value: true }))
			if (userPagesCache.source === 'auth' && !userPagesCache.dirty) {
				dispatch(setUserPagesLoading({ value: false }))
				return
			}
			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
				},
				body: JSON.stringify({
					userId: user.id,
				}),
			}
			fetch(process['env']['REACT_APP_API_ROOT'] + '/document/list/', requestOptions)
				.then((res) => res.json())
				.then((result: ExistingDoc[]) => {
					if (result) {
						dispatch(setUserPagesCache({ source: 'auth', pages: result, dirty: false }))
					}
					dispatch(setUserPagesLoading({ value: false }))
				})
				.catch((e) => {
					Sentry.captureException(e)
					dispatch(setUserPagesLoading({ value: false }))
				})
		}
	}, [currentUser, dispatch, userPagesCache, user])

	useEffect(() => {
		if (userLoading || !isOpen) {
			return
		}
		let existingCookieDocs = cookies.docs
		if (existingCookieDocs && existingCookieDocs.length && !currentUser) {
			getCookieDocuments(existingCookieDocs)
		} else if (currentUser) {
			getUserDocuments()
		}
	}, [currentUser, user, cookies.docs, getCookieDocuments, getUserDocuments, userLoading, dispatch, isOpen])

	const filteredDocs = useMemo(() => {
		if (!searchInput.length) {
			return userPagesCache.pages
		}
		return userPagesCache.pages.filter((page: ExistingDoc) => {
			return page?.title?.toLowerCase().includes(searchInput.toLowerCase())
		})
	}, [searchInput, userPagesCache.pages])

	return (
		<Modal open={isOpen} closeModal={closeModal} portalClassName="existingDocs">
			{!userPagesLoading ? (
				<div>
					<TextareaAutosize
						placeholder="Search documents..."
						id="search"
						autoFocus
						onChange={(e) => {
							setSearchInput(e.target.value)
						}}
						minRows={1}
						maxRows={2}
						value={searchInput}
						className="px-3 py-2"
					/>
					<div className="dividingBorder" />
					{filteredDocs.length ? (
						filteredDocs.map((page: ExistingDoc, index: number) => {
							return (
								<Link
									className="doc px-3 py-1"
									to={CONSTANTS.CREATE_PATH + '/' + page.template + '/' + btoa(page.id.toString())}
									key={index + 'doc'}
									onClick={closeModal}
								>
									{!!page?.title?.length ? page.title : 'Untitled'}
								</Link>
							)
						})
					) : (
						<div className="flex items-center justify-center h-40 text-gray-600">No documents yet</div>
					)}
				</div>
			) : (
				<div className="h-full flex items-center justify-center">
					<Loader />
				</div>
			)}
		</Modal>
	)
}

export default ExistingDocsModal
