import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import Footer from '../Components/Footer'
import './_Post.scss'
import TryFree from '../components-landing/commons/try-free'
import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { FEATURE_FLAGS } from '../hooks/featureFlags'
import Navbar from '../Components/Navbar/Navbar'

type PostProps = {
	path: string
}

type PostType = {
	title: string
	content: string
}

function Post(props: PostProps) {
	const sidePanelFF = useFeatureFlagVariantKey(FEATURE_FLAGS.SIDE_PANEL)

	const pageName = 'post'
	const [post, setPost] = useState<PostType>()

	useEffect(() => {
		fetch(process['env']['REACT_APP_API_ROOT'] + '/cms/get-page/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				path: props.path,
			}),
		})
			.then((res) => res.json())
			.then((data: PostType) => {
				setPost(data)
			})
			.catch((err) => Sentry.captureException(err))
	}, [props.path])

	return (
		<>
			{sidePanelFF === 'test' && <Navbar />}

			<main id={pageName}>
				<div className="px-4 lg:px-56 lg:pt-20 pt-24 max-w-[1800px] mx-auto md:mt-8">
					<h4 className="text-3xl md:text-4xl my-3 leading-normal font-semibold text-gray-700">{post?.title}</h4>
					<div className="border-b-2 border-gray-300 my-8"></div>
					{post && <div className="content leading-loose" dangerouslySetInnerHTML={{ __html: post.content }} />}
				</div>
			</main>
			<div className="mt-24">
				<TryFree />
			</div>
			<Footer />
		</>
	)
}

export default Post
