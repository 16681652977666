import { ArrowRight, Check, GraduationCap } from 'lucide-react'
import FAQSchema from '../components-landing/schema/faq-schema'
import LogoCarousel from '../components-landing/logo-carousel'
import TryFree from '../components-landing/commons/try-free'
import { primaryFaqs } from '../lib/faqs'
import FAQ from '../components-landing/faq'
import ChoppedHumanizerUI from '../assets/images/humanizerui-chopped.png'
import ChopppedDetectorUI from '../assets/images/detectorui-chopped.png'
import ChopppedLeftDetectorUI from '../assets/images/detectorui-chopped-left.png'
import Sapling from '../assets/images/detectors/sapling.png'
import GptZero from '../assets/images/detectors/gptzero.png'
import Turnitin from '../assets/images/detectors/turnitin.png'
import ZeroGpt from '../assets/images/detectors/zerogpt.png'
import BrandWell from '../assets/images/detectors/brandwell.png'
import CopyLeaks from '../assets/images/detectors/copyleaks.png'
import Detection from '../assets/images/detection.png'
import Humanizer from '../assets/images/humanization.png'
import SeoWriter from '../assets/images/seowriter.png'
import WritingTools from '../assets/images/writingtools.png'
import Footer from '../Components/Footer'
import { Editor } from './Humanizer/editor'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import Reviews from '../components-landing/reviews'
import GetStartedButton from '../components-landing/get-started'
import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'
import { useLocalStorage } from '../helpers/utility'
import { zarazLog } from '../helpers/analytics'
import LoginModal from '../Components/Modals/Login'
import { FEATURE_FLAGS } from '../hooks/featureFlags'
import Navbar from '../Components/Navbar/Navbar'
import { Link } from 'react-router-dom'

export function DetectorSection({ switchOrder = false }: { switchOrder?: boolean }) {
	const image = (
		<div className="flex justify-center max-w-xl md:max-w-4xl w-full pl-4 md:pr-0">
			<img
				src={switchOrder ? ChopppedLeftDetectorUI : ChopppedDetectorUI}
				alt="Humanizer UI"
				className="rounded-tl rounded-bl max-w-5xl w-full object-cover shadow-[0_0_50px_20px_rgba(52,110,237,0.3)]"
			/>
		</div>
	)

	const text = (
		<div className="px-2 md:px-0 gap-4">
			<div className="max-w-xl flex flex-col justify-center w-full mx-auto h-full gap-3">
				<div className="flex flex-col gap-3">
					<h2 className="text-4xl lg:text-5xl/tight text-gray-900">
						<span className="text-primary-600">AI Detector:</span> Check Your Content Against Leading AI Detectors
					</h2>
					<div className="text-xl">
						Write with confidence using our AI Humanizer. Use our AI detector to determine if your content passes AI
						checks from the leading AI detection tools, including TurnItIn.
					</div>
				</div>
				<div className="flex flex-col gap-1">
					{['Six different detectors', '99% accuracy', 'Humanize after detection', 'Accurate results in seconds'].map(
						(feature, index) => (
							<div key={index} className="flex gap-2 items-center font-semibold">
								<Check size={20} />
								<div>{feature}</div>
							</div>
						)
					)}
				</div>
			</div>
		</div>
	)
	return (
		<>
			<div
				className={`${
					switchOrder ? 'hidden md:grid' : 'grid md:hidden'
				} md:grid-cols-[1fr,1fr] grid-cols-1 md:gap-24 gap-12`}
			>
				{text}
				{image}
			</div>
			<div
				className={`${
					switchOrder ? 'grid md:hidden' : 'hidden md:grid'
				} md:grid-cols-[1fr,1fr] grid-cols-1 md:gap-24 gap-12`}
			>
				{image}
				{text}
			</div>
		</>
	)
}

export const DetectorDisplaySection = ({ bg = 'bg-primary-50' }: { bg?: string }) => {
	return (
		<div className={`flex flex-col gap-12 py-12 px-2 md:px-0 ${bg}`}>
			<h2 className="text-2xl sm:text-3xl text-gray-900 text-center">
				Verify Instantly With <span className="text-primary-700">Six Built-In AI Detectors</span>
			</h2>
			<div className="grid md:grid-cols-6 grid-cols-2 md:gap-12 gap-8 max-w-7xl mx-auto">
				{[Turnitin, GptZero, BrandWell, CopyLeaks, Sapling, ZeroGpt].map((detector, index) => (
					<div key={index} className="flex flex-col items-center justify-center w-full">
						<img src={detector} />
					</div>
				))}
			</div>
		</div>
	)
}

export function HumanizerSection() {
	return (
		<div className="grid md:grid-cols-[1fr,1fr] grid-cols-1 md:gap-24 gap-12">
			<div className="flex justify-center max-w-xl md:max-w-4xl w-full pr-4 md:pr-0">
				<img
					src={ChoppedHumanizerUI}
					alt="Humanizer UI"
					className="rounded-tr rounded-br shadow-[0_0_50px_20px_rgba(52,110,237,0.3)] max-w-5xl w-full object-cover"
				/>
			</div>
			<div className="flex flex-col justify-center w-full mx-auto px-2 md:px-0 gap-4">
				<div className="flex flex-col gap-3 max-w-xl">
					<h2 className="text-4xl lg:text-5xl/tight text-gray-900">
						<span className="text-primary-600">AI Humanizer:</span> Turn AI Content into Undetectable Human Text
					</h2>
					<div className="text-xl">
						Transform AI-generated content using our AI Humanizer to pass AI content detectors like TurnItIn.
					</div>
				</div>
				<div className="flex flex-col gap-1">
					{[
						'Maintain tone and style',
						'100% undetectable by AI detectors',
						'Multiple humanization levels',
						'Undetectable by TurnItIn',
					].map((feature, index) => (
						<div key={index} className="flex gap-2 items-center font-semibold">
							<Check size={20} />
							<div>{feature}</div>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export const TurnitinBadge = () => {
	return (
		<div className="text-center">
			<Link className="text-primary-700" to="/post/turnitin-update">
				<div className="shadow rounded bg-white py-2 mb-8 font-semibold border border-gray-400 inline-block md:mx-auto text-black mx-2 px-1 md:px-2">
					<div className="bg-indigo-600 text-white block md:inline-block px-2 rounded mr-2 ml-2 md:ml-0 mb-1 md:mb-0">
						New Turnitin Update
					</div>
					Clarify remains undetectable — others aren't.{' '}
					<span className="text-link ml-1">
						Learn More <ArrowRight size={16} className="inline-block mb-1" />
					</span>
				</div>
			</Link>
		</div>
	)
}

export const AllInOneTool = () => {
	const [isMobile, setIsMobile] = useState(false)

	useEffect(() => {
		const userAgent = typeof navigator === 'undefined' ? '' : navigator.userAgent
		setIsMobile(/Mobi|Android/i.test(userAgent))
	}, [])

	const features = [
		{
			video: '/humanizer.mp4',
			title: 'Quality Humanization',
			description:
				'Elevate your content with our AI Humanizer, which transforms dull, mechanical text into engaging, human-like content that resonates with readers.',
		},
		{
			video: '/detector.mp4',
			title: 'Accurate AI Detector',
			description:
				'Check your writing with our AI detector to ensure it passes the top AI detection tools, such as TurnItIn, GPTZero, and more.',
		},
		{
			video: '/writer.mp4',
			title: 'AI SEO Writer',
			description:
				'Create engaging, well-crafted AI humanized content that connects with readers and highlights your insights.',
		},
		{
			video: '/grader.mp4',
			title: 'AI Writing Tools',
			description:
				'Generate content with ease using our AI writing tools, which include our AI grader, AI Autocomplete, and more.',
		},
	]

	return (
		<div className="text-center md:pt-24 pt-8 md:pb-24 pb-8">
			<div className="mx-auto max-w-4xl px-1 md:px-0">
				<h2 className="mt-3 text-4xl sm:text-5xl text-gray-900">
					An <span className="text-primary-600">All-in-One</span> AI Humanizer Tool
				</h2>
				<p className="mt-6 text-lg text-gray-700 mx-auto max-w-3xl">
					Streamline your content creation from start to finish with our AI humanizer—crafting human-sounding content
					has never been easier.
				</p>
			</div>

			<div className="mx-auto max-w-6xl grid md:grid-cols-2 gap-10 mt-16 px-1 md:px-0">
				{features.map((feature, index) => (
					<div key={index} className="flex flex-col gap-4 rounded-xl hover:border-secondary transition-all">
						<video
							src={feature.video}
							className="rounded-xl max-h-80 object-cover shadow border border-gray-300"
							loop
							muted
							playsInline
							controls={false}
							autoPlay={isMobile} // Auto-play on mobile only
							onMouseEnter={(e) => {
								if (!isMobile) {
									e.currentTarget.play()
								}
							}}
							onMouseLeave={(e) => {
								if (!isMobile) {
									e.currentTarget.pause()
									e.currentTarget.currentTime = 0 // reset if you'd like the video to restart on next hover
								}
							}}
						/>

						<div className="text-2xl flex items-center gap-2 font-semibold">{feature.title}</div>
						<div className="text-gray-800 text-left">{feature.description}</div>
					</div>
				))}
			</div>
		</div>
	)
}

export default function Home() {
	const [loginModalOpen, setLoginModalOpen] = useState(false)
	const [bannerOpen] = useLocalStorage('bannerOpen', false)
	const [annualPlan] = useLocalStorage('annualPlan', true)
	const posthog = usePostHog()
	const sidePanelFF = useFeatureFlagVariantKey(FEATURE_FLAGS.SIDE_PANEL)
	const turnitinBadge = useFeatureFlagVariantKey(FEATURE_FLAGS.TURNITIN_BADGE)
	const { ref, inView } = useInView({
		/* Optional options */
		threshold: 0,
	})

	useEffect(() => {
		posthog.capture('page-viewed', { page: 'home' })
		window.scrollTo(0, 0)
		zarazLog('PageView')
	}, [])

	return (
		<>
			{sidePanelFF === 'test' && <Navbar />}
			<LoginModal
				premiumPath={false}
				open={loginModalOpen}
				setModalOpen={(open: boolean) => {
					setLoginModalOpen(open)
				}}
				annualPlan={annualPlan}
				loginVariant="signup"
				redirectUrl="/choose"
			/>
			<FAQSchema faqs={primaryFaqs} />
			<div className="relative">
				<div className="w-full h-full bg-gradient-to-b from-white to-[#346eed]  absolute bottom-[300px] opacity-20 z-[-10] md:block hidden" />
				<div className={`mx-auto ${bannerOpen ? 'mt-44' : 'mt-28'} w-full max-w-screen-xl px-4 md:px-0 md:mt-32`}>
					<div className="mx-auto w-full max-w-4xl text-center flex flex-col items-center">
						{turnitinBadge === 'test' && <TurnitinBadge />}
						<h1 className="text-4xl leading-tight sm:text-7xl font-[500] text-gray-900">
							Transform Your Writing with <span className="text-primary-600">AI Magic</span>
						</h1>
						<p className="mt-3 md:text-xl text-lg mb-8">
							<span className="font-semibold">Join 4M+ writers.</span> Turn AI-generated content into human text with
							our leading AI Humanizer. Write faster and boost your productivity—all 100% undetectable by TurnItIn and
							other AI detectors.
						</p>
						<GetStartedButton redirectUrl="/choose" />
					</div>
					<div
						className={`mt-10 hidden md:block transform transition-all duration-700 ${
							inView ? 'opacity-100 scale-100' : 'opacity-0 scale-90'
						}`}
						ref={ref}
					>
						<Editor />
					</div>
					<div className="mt-10 block md:hidden">
						<video
							src="/humanizer.mp4"
							className="rounded-xl shadow"
							autoPlay
							loop
							muted
							playsInline
							controls={false}
						/>
					</div>
				</div>
			</div>
			<div className="mx-auto mt-24 w-full">
				<div className="mt-12 text-center">
					<div className="mb-4 flex flex-row justify-center gap-2">
						<GraduationCap className="text-slate-500" />
						<h3 className="font-medium ">Trusted by Writers At Top Colleges and Universities</h3>
					</div>
					<LogoCarousel />
				</div>
				<div className="md:mt-36 mt-24">
					<HumanizerSection />
					<div className="md:mt-36 mt-24">
						<DetectorSection switchOrder />
					</div>
					<div className="md:mt-36 mt-24 mb-12">
						<DetectorDisplaySection />
					</div>
					{/* <div className="flex flex-col md:gap-4 gap-6 mt-16">
						<div className="max-w-5xl flex justify-between items-end w-full mx-auto flex-wrap gap-4">
							<div className="flex flex-col gap-3 max-w-xl">
								<div className="text-gray-700 text-sm font-bold">Write</div>
								<h2 className="text-4xl sm:text-4xl/tight text-gray-900">
									AI Writer: Generate Humanized Essays and Blog Posts in Seconds
								</h2>
								<div className="text-xl">
									Generate humanized content within seconds that includes in-text citations from real articles,
									journals, and websites.
								</div>
							</div>
							<div className="flex flex-col gap-1">
								{[
									'References and in-text citations',
									'100% AI undetectable',
									'Built-in AI detector',
									'Personalized writing tone and style',
								].map((feature, index) => (
									<div key={index} className="flex gap-2 items-center text-gray-800">
										<Check size={20} />
										<div>{feature}</div>
									</div>
								))}
							</div>
						</div>
						<div className="rounded flex justify-center md:p-5">
							<img src={WriterUI} alt="Writer UI" className="rounded max-w-5xl w-full" />
						</div>
					</div> */}
					<div className="mb-12 md:mb-0">
						<AllInOneTool />
					</div>
				</div>
				<div className=" bg-primary-50 py-12">
					<Reviews />
				</div>
				<div className="max-w-5xl mx-auto">
					<FAQ />
				</div>
				<div className="mb-12 mt-16 md:mt-40">
					<TryFree />
				</div>
			</div>
			<Footer />
		</>
	)
}
