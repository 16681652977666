import { FolderOpen, Home, Menu, Pencil, Settings, X, Zap } from 'lucide-react'
import { Button } from '../../components-landing/ui/button'
import Logo from '../Logo'
import { PiHeadCircuit } from 'react-icons/pi'
import { LuSearchCheck } from 'react-icons/lu'
import { HiOutlineDocumentText } from 'react-icons/hi2'
import { useHistory } from 'react-router-dom'
import useNewDocument from '../../hooks/useNewDocument'
import { DOC_TEMPLATES } from '../../constants'
import { useEffect, useMemo, useState } from 'react'
import useIsPremium, { useIsOnFreeTrial } from '../../hooks/useIsPremium'
import AccountModal from '../Modals/Account'
import Subscriptions from '../Modals/Subscriptions'
import { useAuth } from '../../contexts/Auth'
import ExistingDocsModal from '../Modals/Docs'
import Navbar from '../Navbar/Navbar'
import CreditsBar from './CreditsBar'
import GoPremium from '../Modals/GoPremium'
import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { FEATURE_FLAGS } from '../../hooks/featureFlags'

const SidePanelLayout = ({ children, ...props }: { children: React.ReactNode }) => {
	const history = useHistory()
	const { createDoc } = useNewDocument()
	const isPremium = useIsPremium()
	const sidePanelFF = useFeatureFlagVariantKey(FEATURE_FLAGS.SIDE_PANEL)
	const [accountModalOpen, setAccountModalOpen] = useState(false)
	const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false)
	const [existingDocsModalOpen, setExistingDocsModalOpen] = useState(false)
	const [goPremiumModalOpen, setGoPremiumModalOpen] = useState(false)
	const [sidePanelOpen, setSidePanelOpen] = useState(false)
	const { currentUser } = useAuth() as any
	const isOnFreeTrial = useIsOnFreeTrial()

	const SidePanelButton = useMemo(
		() =>
			({ onClick, urlMatching, children, ...props }: any) => {
				return (
					<Button
						variant="ghost"
						className={`w-full hover:bg-primary-50 hover:text-primary-800 ${
							history.location.pathname.includes(urlMatching) ? 'bg-primary-50 text-primary-800' : ''
						}`}
						{...props}
						onClick={() => {
							onClick()
							setSidePanelOpen(false)
						}}
					>
						{children}
					</Button>
				)
			},
		[history.location.pathname]
	)

	useEffect(() => {
		if (!currentUser) {
			window.location.href = '/'
		}
	}, [currentUser])

	const upgradeToPremium = () => {
		if (isOnFreeTrial) {
			setGoPremiumModalOpen(true)
		} else {
			setSubscriptionModalOpen(true)
		}
	}

	if (!sidePanelFF || sidePanelFF !== 'test') {
		return <>{children}</>
	}

	if (!currentUser) {
		return null
	}

	const SidePanelContent = (
		<div
			className={`bg-white border-r border-gray-300 p-5 h-screen sticky top-0 overflow-y-auto flex flex-col justify-between md:w-[350px] ${
				sidePanelOpen ? 'flex w-full' : 'md:flex hidden'
			}`}
		>
			<Logo />
			<div className="flex flex-col gap-4 mt-8 grow">
				<SidePanelButton urlMatching="choose" onClick={() => history.push('/choose')}>
					<div className="flex items-center gap-2 w-full">
						<Home size={20} />
						<div>Dashboard</div>
					</div>
				</SidePanelButton>
				<SidePanelButton onClick={() => setExistingDocsModalOpen(true)}>
					<div className="flex items-center gap-2 w-full">
						<FolderOpen size={20} />
						<div>Documents</div>
					</div>
				</SidePanelButton>
				<div className="border-t border-gray-400 my-3" />
				<SidePanelButton urlMatching="humanizer" onClick={() => history.push('/humanizer')}>
					<div className="flex items-center gap-2 w-full">
						<PiHeadCircuit size={20} className="transform -scale-x-100" />
						<div>AI Humanizer</div>
					</div>
				</SidePanelButton>
				<SidePanelButton urlMatching="detector" onClick={() => history.push('/detector')}>
					<div className="flex items-center gap-2 w-full">
						<LuSearchCheck size={20} />
						<div>AI Detector</div>
					</div>
				</SidePanelButton>
				<SidePanelButton urlMatching="Writer" onClick={() => createDoc(DOC_TEMPLATES.Writer)}>
					<div className="flex items-center gap-2 w-full">
						<Pencil size={20} />
						<div>AI Writer</div>
					</div>
				</SidePanelButton>
				<SidePanelButton urlMatching="Outline" onClick={() => createDoc(DOC_TEMPLATES.Outline)}>
					<div className="flex items-center gap-2 w-full">
						<HiOutlineDocumentText size={20} />
						<div>AI Outliner</div>
					</div>
				</SidePanelButton>
			</div>
			<div className="flex flex-col">
				{!isPremium && (
					<>
						<SidePanelButton onClick={upgradeToPremium} className="justify-center">
							<div className="flex items-center gap-2 w-full justify-center text-primary-800">
								<Zap size={18} />
								<div>Premium</div>
							</div>
						</SidePanelButton>
						<div className="border-t border-gray-400 my-5" />
					</>
				)}
				<CreditsBar onClick={upgradeToPremium} />
				<div className="border-t border-gray-400 my-5" />
				<SidePanelButton onClick={() => setAccountModalOpen(true)} className="px-0">
					{currentUser.email?.length > 0 ? (
						<div className="flex items-center gap-2 w-full">
							<div className="w-8 h-8 bg-secondary text-white rounded-full flex items-center justify-center shrink-0">
								{currentUser.email[0].toUpperCase()}
							</div>
							<div className="truncate">{currentUser.email}</div>
						</div>
					) : (
						<div className="flex items-center gap-2 w-full">
							<Settings size={20} />
							<div>Settings</div>
						</div>
					)}
				</SidePanelButton>
			</div>
		</div>
	)

	return (
		<>
			<ExistingDocsModal isOpen={existingDocsModalOpen} closeModal={() => setExistingDocsModalOpen(false)} />
			<Subscriptions
				open={subscriptionModalOpen}
				closeModal={() => {
					setSubscriptionModalOpen(false)
				}}
			/>
			<GoPremium
				open={goPremiumModalOpen}
				setModalOpen={(open: boolean) => {
					setGoPremiumModalOpen(open)
				}}
			/>
			<AccountModal isOpen={accountModalOpen} closeModal={() => setAccountModalOpen(false)} />
			<div className="flex w-full min-h-screen shrink-0">
				{SidePanelContent}
				<div className={`fixed z-50 block md:hidden top-1 ${sidePanelOpen ? 'right-1' : 'left-1'}`}>
					<Button variant="ghost" onClick={() => setSidePanelOpen(!sidePanelOpen)} className="bg-white">
						<Menu className={`${sidePanelOpen ? 'hidden' : 'md:hidden'}`} />
						<X className={`${sidePanelOpen ? 'md:hidden' : 'hidden'}`} />
					</Button>
				</div>
				<div className={`bg-gray-50 w-full ${sidePanelOpen ? 'hidden md:block' : ''}`}>
					<Navbar />
					{children}
				</div>
			</div>
		</>
	)
}

export default SidePanelLayout
