export const handlePurchaseEvent = (purchaseValue: number) => {
	// @ts-ignore
	if (window.gtag) {
		// @ts-ignore
		window.gtag('config', 'AW-16752782987')
		// @ts-ignore
		window.gtag('event', 'conversion', {
			send_to: 'AW-16752782987/XfU8COyGwvUZEIvdrLQ-',
			value: purchaseValue || 1.0,
			currency: 'USD',
			transaction_id: '',
		})
	} else {
		console.error('Google Tag Manager is not initialized.')
	}
}
export const handleSignupEvent = () => {
	// @ts-ignore
	if (window.gtag) {
		// @ts-ignore
		window.gtag('config', 'AW-16752782987')
		// @ts-ignore
		gtag('event', 'conversion', {
			send_to: 'AW-16752782987/yErwCMutvPgZEIvdrLQ-',
			value: 1.0,
		})
	} else {
		console.error('Google Tag Manager is not initialized.')
	}
}

export const handleCreditsExceededEvent = () => {
	// @ts-ignore
	if (window.gtag) {
		// @ts-ignore
		window.gtag('config', 'AW-16752782987')
		// @ts-ignore
		gtag('event', 'conversion', {
			send_to: 'AW-16752782987/LgUmCM6tvPgZEIvdrLQ-',
			value: 1.0,
		})
	} else {
		console.error('Google Tag Manager is not initialized.')
	}
}

export const zarazLog = (event: string, options?: object) => {
	try {
		// @ts-ignore
		window.zaraz.track(event, options)
	} catch (error) {
		console.error('Zaraz tracking failed', error)
	}
}
