import { useFeatureFlagVariantKey } from 'posthog-js/react'

export const FEATURE_FLAGS = {
	STEALTH_GPT: 'stealth-gpt',
	ASK_AI_PROMPT: 'ask-ai-prompt',
	CRISP_CHAT: 'crisp-chat',
	PRICING_V1: 'pricing-v1',
	NO_FREE_TRIAL: 'no-free-trial',
	BLUR: 'blur',
	SIGN_UP_PANEL: 'sign-up-panel',
	EMAIL_VERIFICATION: 'email-verification',
	SIDE_PANEL: 'side-panel',
	SITE_DESIGN_V2: 'site-design-v2',
	TURNITIN_BADGE: 'turnitin-badge',
	DEFAULT_MONTHLY: 'default-monthly',
}

export const usePricing = () => {
	const pricingV1FF = useFeatureFlagVariantKey(FEATURE_FLAGS.PRICING_V1)
	const defaultPricing = {
		monthly: '9',
		monthlyOriginal: '11.25',
		yearly: '6',
		yearlyOriginal: '7.50',
		yearlyTotal: '72',
		discount: '30',
		stripePriceId: { yearly: 'price_1QFO5KEfEl3tySBoXqs0S2Gh', monthly: 'price_1QFO5WEfEl3tySBo7weSUbFf' },
	}

	const pricingV1 = {
		monthly: '19.99',
		monthlyOriginal: '23.99',
		yearly: '10.99',
		yearlyOriginal: '12.99',
		yearlyTotal: '131.88',
		discount: '45',
		stripePriceId: { yearly: 'price_1Q9nrlEfEl3tySBofcsAxpgl', monthly: 'price_1Q9nnyEfEl3tySBoaDHkeboY' },
	}

	return pricingV1FF === 'test' ? pricingV1 : defaultPricing
}

export const useFreeWordLimit = () => {
	return 150
}
