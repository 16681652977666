import { useAuth } from '../../contexts/Auth'
import { CONSTANTS } from '../../constants'
import { usePostHog } from 'posthog-js/react'
import { useFreeWordLimit, usePricing } from '../../hooks/featureFlags'
import useCheckout from '../../hooks/checkout'
import { FaRegCircleCheck } from 'react-icons/fa6'
import UsersImg from '../../assets/images/users.png'
import { useMemo } from 'react'
import { Button } from '../../components-landing/ui/button'

function Premium({
	closeParentModal,
	setLoginModal,
	annualPlan,
	setAnnualPlan,
	shorten,
	noMoreCredits = false,
}: {
	closeParentModal: () => void
	setLoginModal: (open: boolean) => void
	annualPlan: boolean
	setAnnualPlan: (annual: boolean) => void
	shorten?: boolean
	noMoreCredits?: boolean
}) {
	const posthog = usePostHog()
	const { checkout } = useCheckout()
	const pricing = usePricing()
	const { currentUser } = useAuth() as any
	const freeWordLimit = useFreeWordLimit()
	const pathname = window.location.pathname

	function openNextStep(freeTrial: boolean) {
		posthog.capture('upgrade-now-clicked')
		if (!currentUser) {
			setLoginModal(true)
			closeParentModal()
		} else {
			checkout(annualPlan, freeTrial)
			closeParentModal()
		}
	}

	const sellingPoints = useMemo(() => {
		let basePoints = [
			`${CONSTANTS.WORD_COUNT_MAX} Word Generations`,
			'Unlimited Generations',
			'Advanced Generation Options',
		]
		if (pathname.includes('humanizer') || pathname.includes('/')) {
			basePoints.unshift('Unlimited AI Detection')
			basePoints.unshift('Unlimited AI Humanizing')
			return basePoints
		} else if (pathname.includes('detector')) {
			basePoints.unshift('Unlimited AI Humanizing')
			basePoints.unshift('Unlimited AI Detection')
			return basePoints
		}
		basePoints.push('AI Humanizer & Detector')
		return basePoints
	}, [pathname])

	return (
		<div className={`flex flex-col ${shorten ? 'gap-3' : !!pricing.yearly ? 'gap-4' : 'gap-6'}`}>
			<h6 className="text-2xl text-left">{noMoreCredits ? "You've run out of credits!" : 'Upgrade to Premium'}</h6>
			<p className="text-[18px] text-gray-700 mb-0 text-left">Supercharge your writing with a premium plan.</p>
			{!!pricing.yearly && (
				<div className="flex flex-col items-center">
					<div className="rounded-full p-1 flex gap-2 border border-gray-400">
						<div
							className={`cursor-pointer rounded-full py-1 px-3 text-sm font-semibold ${
								!annualPlan ? 'bg-gray-300 text-gray-900' : 'text-gray-700'
							}`}
							onClick={() => {
								setAnnualPlan(false)
							}}
						>
							Monthly
						</div>
						<div
							className={`cursor-pointer rounded-full py-1 pl-3 pr-2 text-sm font-semibold ${
								annualPlan ? 'bg-gray-300 text-gray-900' : 'text-gray-700'
							}`}
							onClick={() => {
								setAnnualPlan(true)
							}}
						>
							Annual
							<span className="pl-1 pr-2 py-0.5 bg-success-light rounded-full text-xs border border-success-default text-success-dark ml-2">
								{' '}
								Save {pricing.discount}%
							</span>
						</div>
					</div>
				</div>
			)}
			<div className="flex justify-between flex-wrap gap-4">
				{!shorten && (
					<div className="flex flex-col gap-2">
						<h6 className="text-2xl text-left font-bold mt-1">FREE</h6>
						<div className="flex flex-col gap-2">
							{[`${freeWordLimit} Word Generations`, 'Unlimited Generations', 'Basic Generation Options'].map(
								(item, index) => {
									if (!item) return null
									return (
										<div key={index} className="flex gap-2 items-center">
											<FaRegCircleCheck className="text-success-default text-xl" />
											<div>{item}</div>
										</div>
									)
								}
							)}
						</div>
					</div>
				)}
				<div className="flex flex-col gap-2">
					<div className="flex flex-col gap-2">
						<div className="flex gap-1 items-baseline">
							<div className="text-3xl font-bold leading-0 m-0">${annualPlan ? pricing.yearly : pricing.monthly}</div>
							<div className="text-sm relative bottom-0.5">/month</div>
						</div>
					</div>
					<div className="flex flex-col gap-2">
						{sellingPoints.map((item, index) => {
							if (!item) return null
							return (
								<div key={index} className="flex gap-2 items-center">
									<FaRegCircleCheck className="text-success-default text-xl" />
									<div>{item}</div>
								</div>
							)
						})}
					</div>
				</div>
			</div>
			{/* {pricing.yearly && (
				<div className="flex flex-col gap-3">
					<div
						className={`flex justify-between border px-3 py-2 cursor-pointer ${
							annualPlan ? 'border-[#20718B] border-2 bg-gray-100' : 'border-gray-400'
						}`}
						onClick={() => {
							setAnnualPlan(true)
							setAnnualPlan(true)
						}}
					>
						<div className="flex justify-between gap-2 items-center">
							<div className="font-semibold text-sm">Annual</div>
							<div className="border border-success-default bg-success-light rounded-lg text-xs text-success-dark px-1">
								Save {pricing.discount}%
							</div>
						</div>
						<div className="text-sm font-semibold">${pricing.yearly}.00/mo</div>
					</div>
					<div
						className={`flex justify-between border px-3 py-2 cursor-pointer ${
							!annualPlan ? 'border-[#20718B] border-2 bg-gray-100' : 'border-gray-400'
						}`}
						onClick={() => {
							setAnnualPlan(false)
							setAnnualPlan(false)
						}}
					>
						<div className="flex justify-between gap-2 items-center">
							<div className="font-semibold text-sm">Monthly</div>
						</div>
						<div className="text-sm font-semibold">${pricing.monthly}.00/mo</div>
					</div>
				</div>
			)} */}
			<Button onClick={() => openNextStep(true)} variant="outline">
				<span>Start Free Trial</span>
			</Button>
			<Button onClick={() => openNextStep(false)}>
				<span>Upgrade Plan</span>
			</Button>
			<div className="flex gap-2 items-center">
				<img src={UsersImg} alt="Users" className="h-12" />
				<div className="flex flex-col gap-0 items-start">
					<p className="m-0 text-sm">Join over 4 million students</p>
					<p className="m-0 text-sm">writing with Clarify</p>
				</div>
			</div>
		</div>
	)
}

export default Premium
